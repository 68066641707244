import { Button, Title, WhistleIcon } from "@gamesheet/ui";
import { useEffect, useMemo, useState } from "react";
import { useAppState } from "@/state/app/useAppState";
import { styled } from "styled-components";
import { Roster, RosterPlayer } from "@/state/data/useTeamRoster/types";
import { MemberClaim } from "@/services/useMemberService/types.ts/member";
import { TeamRelationshipOption } from "./components/TeamRelationshipOption";
import blackTrashCan from "@/assets/images/black_trash_can.svg"
import { set } from "lodash";


const DesktopTeamRelationship = styled.div`

.team-selector {
    
    padding-top: 0;

    .team-logo {
        padding-left: 8px;
        img {
            width: 96px;
            height: 96px;
        }
    }

    .team-title {
        .title {
            font-size: 1rem;
        }
        .subtitle {
            font-size: 1.1rem;
        }
    }
}

.dropdown {
    display: flex;
    flex-direction: column;
    background-color: '#fafafa';
    border-radius: 8px;
    box-shadow: 0px 0px 10px 0px rgba(54, 56, 61, 0.2);
    cursor: pointer;
    width: 100%;
    padding: 10px;
    gap: 10px;

    .form-control {
        padding: 14px;
    }

    .info {
        font-size: 14px;
        font-color: #000000;
    }

    .info-small {
        font-size: 12px;
        font-color: #404247;
    }
}
`

const MobileTeamRelationship = styled.div`

    .team-selector {
        
        margin-top: 15px;
        margin-bottom: 15px;

        .team-logo {
            padding-left: 8px;
        }

    }

    .dropdown {
        display: flex;
        flex-direction: column;
        background-color: '#fafafa';
        border-radius: 8px;
        box-shadow: 0px 0px 10px 0px rgba(54, 56, 61, 0.2);
        cursor: pointer;
        width: 100%;
        padding: 10px;
        gap: 10px;

        .form-control {
            padding: 14px;
        }

        .info {
            font-size: 14px;
            font-color: #000000;
        }

        .info-small {
            font-size: 12px;
            font-color: #404247;
        }
    }
`

const DropdownContainer = styled.div`
  position: relative;
`;

const DropdownList = styled.div`
  list-style-type: none;
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  background: #F5F5F4;
  border-radius: 0px 0px 4px 4px;
  z-index: 1000;
  margin-top: -10px;
`;

const DropdownItem = styled.li`
  padding: 8px;
  cursor: pointer;
  &:hover {
    background-color: #ffc107;
  }
`;

const SubTitle = styled.div`
    font-size: 18px;
    font-weight: 400;
    margin-top: 16px;
`

const StyledButton = styled.button`
  border: 2px solid #36383D;
  background-color: #ffffff;
  color: #36383D;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  width: 60%;
  align-self: center;
  font-weight: 500;
  font-size: 16px;
  
  &:hover {
    background-color: #e0e0e0;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(54, 56, 61, 0.5);
  }
`;

const RemoveButton = styled.button`
  background: url(${blackTrashCan}) no-repeat center;
  background-size: contain;
  border: none;
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 28px;
  transform: translateY(-50%);
  &:hover {
    filter: brightness(0.8);
  }
`;

type TeamRelationshipProps = {
    inviteType: MemberClaim;
    hasNumber: boolean;
    title: string;
    players: RosterPlayer[];
    fromEmail: boolean,
    migrate: boolean;
    register: boolean;
    onNext: (relationship: string, relatedPlayers?: RosterPlayer[]) => void;
    onBack: () => void;
}

export function TeamRelationship({ inviteType, hasNumber, title, fromEmail, onBack, players, migrate, register, onNext }:TeamRelationshipProps){
    
    const app = useAppState()
    const StyledTeamPreview = (app.layout.showTablet || app.layout.showDesktop) ? DesktopTeamRelationship : MobileTeamRelationship;
    const [ selectedOption, setSelectedOption ] = useState("")
    const [ selectedCoachOption, setSelectedCoachOption ] = useState("")
    const [ teamRelationship, setTeamRelationship ] = useState<any>()
    const [ valid, setValid ] = useState(false);
    const buttonText = hasNumber && inviteType.role == "follower" ? "Accept" : "Continue";

    const coachOptions = [
        { value: "head_coach", label: "Head Coach" },
        { value: "assistant_coach", label: "Assistant Coach" },
        { value: "manager", label: "Manager" },
        { value: "trainer", label: "Trainer" },
        { value: "team_captain", label: "Team Captain" },
        { value: "team_helper", label: "Team Helper" },
        { value: "other", label: "Other" },
    ];

    useEffect(() => {
        if (fromEmail) setSelectedOption("coach");
    }, [fromEmail])

    const handleOptionClick = (option: string) => { 
        setSelectedOption(option === selectedOption ? "" : option);
    }

    // Player relationship variables
    const [ playerInput, setPlayerInput ] = useState("")
    const [ playerFilteredPlayers, setPlayerFilteredPlayers] = useState<RosterPlayer[]>([]);
    const [ playerSelectedPlayer, setPlayerSelectedPlayer ] = useState<string>("");
    useEffect(() => {
        if (playerInput === '') {
          setPlayerFilteredPlayers([]);
        } else {
          const filtered = players.filter(player => `${player.firstName.toLowerCase()} ${player.lastName.toLowerCase()}`.includes(playerInput.toLowerCase()));
          setPlayerFilteredPlayers(filtered);
        }
    }, [playerInput, JSON.stringify(players)]);

    // Parent relationship variables
    const [ parentFilteredPlayers, setParentFilteredPlayers] = useState<RosterPlayer[]>([]);
    const [ parentPlayerInputs, setParentPlayerInputs] = useState([{ id: 0, value: "", player_id: "", selected: false }]);

    const handleParentInputChange = (index: number, value: string) => {
        const newInputs = [...parentPlayerInputs];
        newInputs[index].value = value;
        newInputs[index].player_id = "";
        newInputs[index].selected = false;
        setParentPlayerInputs(newInputs);
        if (value === '') {
            setParentFilteredPlayers([]);
        } else {
            const filtered = players.filter((player) =>
                `${player.firstName.toLowerCase()} ${player.lastName.toLowerCase()}`.includes(value.toLowerCase()) &&
                !parentPlayerInputs.some((input) => input.player_id === player.id)
            );
            setParentFilteredPlayers(filtered);
        }
    };

    const addParentPlayerInput = () => {
        setParentPlayerInputs([...parentPlayerInputs, { id: parentPlayerInputs.length, value: "", player_id: "", selected: false}]);
    };

    const selectPlayerFromDropdown = (index: number, player: RosterPlayer) => {
        const newInputs = [...parentPlayerInputs];
        newInputs[index].value = player.firstName + " " + player.lastName;
        newInputs[index].player_id = player.id;
        newInputs[index].selected = true;
        setParentPlayerInputs(newInputs);
    }

    const removeParentPlayerInput = (index: number) => {
        const newInputs = parentPlayerInputs
          .filter((_, i) => i !== index)
          .map((input, i) => ({
            ...input,
            id: i,
          }));
        setParentPlayerInputs(newInputs);
    };

    useEffect(() => {
        switch(selectedOption) {
            case "parent":
                if (parentPlayerInputs.some(input => input.selected === false)) {
                    setValid(false);
                    return;
                }
                const relatedPlayers = players.filter(player => parentPlayerInputs.some(input => input.player_id === player.id));
                setTeamRelationship({relationship: "parent", relatedPlayers: relatedPlayers});
                setValid(true);
                break;
            case "player":
                if (playerSelectedPlayer === "") {
                    setValid(false);
                    return;
                }
                setTeamRelationship({relationship: "player", relatedPlayers: players.find(player => player.id == playerSelectedPlayer)});
                setValid(true);
                break;
            case "fan":
                setTeamRelationship({relationship: "fan"});
                setValid(true);
                break;
            case "other":
                setTeamRelationship({relationship: "other"});
                setValid(true);
                break;
            case "coach":
                if (selectedCoachOption === "") {
                    setValid(false);
                    return;
                }
                setTeamRelationship({relationship: selectedCoachOption});
                setValid(true);
                break;
            case "admin":
                setTeamRelationship({relationship: "admin"});
                setValid(true);
                break;
            default:
                setTeamRelationship(undefined);
                setValid(false);
        }
    }, [selectedOption, selectedCoachOption, playerSelectedPlayer, JSON.stringify(parentPlayerInputs)])

    const BtnBack = useMemo(() => <Button type="button" size="lg" variant={!migrate ? "inverted" : "muted"} style={register ? {} : {marginLeft: '16px', height: '36px', marginBottom: '-100px'}} disabled={migrate} onClick={onBack}>Back</Button>, [ onBack, migrate ])
    useEffect(() => app.ui('leftTray').set(BtnBack, app.layout.showMobile), [ BtnBack, app.layout.showMobile ])

    const BtnNext = useMemo(() => <Button type="button" size="lg" variant={valid ? "" : "muted"} style={register ? {} : {marginRight: '16px', height: '36px', marginBottom: '-100px'}} disabled={!valid} onClick={() => {onNext(teamRelationship.relationship, teamRelationship?.relatedPlayers)}}>{buttonText}</Button>, [ onNext, JSON.stringify(teamRelationship), valid ])
    useEffect(() => app.ui('rightTray').set(BtnNext, app.layout.showMobile), [ BtnNext, app.layout.showMobile ])

    return <StyledTeamPreview>
        
        <SubTitle>What is your relationship to the {title}?</SubTitle>

        { inviteType.role == "follower" && <div style={{ display: "flex", flexDirection: "column", gap: "12px", paddingTop: "12px" }}>
            <div>
                <TeamRelationshipOption 
                    onClick={() => {handleOptionClick("parent")}} 
                    icon="HouseIcon"
                    selected={selectedOption == "parent"}
                    text="I’m a Parent/Guardian of one of the Players on the Team"
                    staff={false} 
                    data-mf-tags="team-relationship-parent"
                />
                {selectedOption == "parent" && (
                <DropdownContainer>
                    <div className="dropdown">
                        <div className="info">What’s the name of your player?</div>
                        {parentPlayerInputs.map((input, index) => (
                            <div key={index} style={{ position: "relative" }}>
                            <input
                                required
                                type="text"
                                name={`parentInput-${index}`}
                                id={`parentInput-${index}`}
                                className="form-control"
                                placeholder="Player Name*"
                                onChange={(e) => handleParentInputChange(index, e.target.value)}
                                value={input.value}
                            />
                            {index > 0 && (
                                <RemoveButton onClick={() => removeParentPlayerInput(index)} />
                            )}
                            {parentFilteredPlayers.length > 0 && (parentPlayerInputs[index].value !== "") && !(parentPlayerInputs[index].selected) && (
                                <DropdownList>
                                {parentFilteredPlayers.map((player, pindex) => (
                                    <DropdownItem key={pindex} onClick={() => {selectPlayerFromDropdown(index, player)}}>
                                        {player.firstName + " " + player.lastName}
                                    </DropdownItem>
                                ))}
                                </DropdownList>
                            )}
                            </div>
                        ))}
                        { parentPlayerInputs[0].selected && 
                            <StyledButton onClick={addParentPlayerInput}>
                                + Another player
                            </StyledButton> 
                        }
                        <div className="info-small">Begin typing the player's name and select the correct one from the list, or complete typing their full name.</div>
                    </div>
                </DropdownContainer>
            )}
            </div>
            <div>
                <TeamRelationshipOption 
                    onClick={() => {handleOptionClick("player")}} 
                    icon="BlankJerseyIcon"
                    selected={selectedOption == "player"}
                    text="I’m a Player on the Team"
                    staff={false}   
                    data-mf-tags="team-relationship-player"
                />
                {selectedOption == "player" && (
                    <DropdownContainer>
                        <div className="dropdown">
                            <div className="info">Which player are you?</div>
                            <input 
                            required 
                            type="text" 
                            name="playerInput" 
                            id="playerInput" 
                            className="form-control"
                            placeholder="Player Name*" 
                            onChange={(e) => {setPlayerInput(e.target.value); setPlayerSelectedPlayer("")}} 
                            value={playerInput}
                            />
                        {playerFilteredPlayers.length > 0 && playerSelectedPlayer === "" && (
                            <DropdownList>
                            {playerFilteredPlayers.map((player, index) => (
                                <DropdownItem 
                                key={index} 
                                onClick={() => {setPlayerSelectedPlayer(player.id); setPlayerInput(player.firstName + " " + player.lastName)}}
                                >
                                {player.firstName + " " + player.lastName}
                                </DropdownItem>
                            ))}
                            </DropdownList>
                        )}
                        <div className="info-small">Begin typing your name and select the correct one from the list, or complete typing your full name.</div>
                        </div>
                    </DropdownContainer>
                )}
            </div>
            <TeamRelationshipOption 
                onClick={() => {handleOptionClick("fan")}} 
                icon="FoamFingerIcon"
                selected={selectedOption == "fan"}
                text="I’m a fan, and I’m Interested in Following the Team"
                staff={false}    
                data-mf-tags="team-relationship-fan"
            />
            <TeamRelationshipOption 
                onClick={() => {handleOptionClick("other")}} 
                icon="BinocularIcon"
                selected={selectedOption == "other"}
                text="Other"
                staff={false}  
                data-mf-tags="team-relationship-other"  
            />
        </div>}

        { inviteType.role == "staff" && <div style={{ display: "flex", flexDirection: "column", gap: "12px", paddingTop: "12px" }}>
            <div>
                <TeamRelationshipOption 
                    onClick={() => {handleOptionClick("coach")}} 
                    icon="WhistleIcon"
                    selected={selectedOption == "coach"}
                    text="I have a direct relationship with the team. Roles can include Head Coach, Helpers, Trainer, Manager, etc."
                    staff={true}
                    style={(selectedOption == "coach") ? {borderRadius: "8px 8px 0px 0px"} : {}}
                    data-mf-tags="team-relationship-coach"
                />
                {selectedOption == "coach" && <div className="dropdown">
                    {coachOptions.map(option => (
                    <div key={option.value}>
                        <input
                        type="radio"
                        id={option.value}
                        name="coach-select"
                        value={option.value}
                        checked={selectedCoachOption === option.value}
                        onChange={() => setSelectedCoachOption(option.value)}
                        />
                        <label htmlFor={option.value} style={{ marginLeft: "8px" }}>{option.label}</label>
                    </div>
                    ))}
                </div>}
            </div>
            { !fromEmail && <TeamRelationshipOption 
                onClick={() => {handleOptionClick("admin")}} 
                icon="EyeIcon"
                selected={selectedOption == "admin"}
                text="I am associated with the team but do not manage it directly. Roles can include Regional Director, Division Coordinator, Club Admin, etc."
                staff={true}    
                data-mf-tags="team-relationship-admin"
            />}
        </div>}
        
        { (app.layout.showTablet || app.layout.showDesktop) && <>
            <hr/>
            <div style={{ display:'flex', justifyContent:'space-between' }}>
                {BtnBack}
                {BtnNext}
            </div>
        </>}

    </StyledTeamPreview>

}