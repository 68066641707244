import { useRecoilState, useResetRecoilState } from "recoil";
import { LoginFlowState } from "./atom.loginFlowState";
import { LoginFlowPath } from "../types";
import { useAppState } from "@/state/app/useAppState";
import { useCallback, useEffect, useMemo } from "react";

export function useLoginFlow(path:LoginFlowPath){

    const app = useAppState()
    const resetLoginFlow = useResetRecoilState(LoginFlowState)
    const [state, setState] = useRecoilState(LoginFlowState)
    
    const reset = useCallback(() => {
        
        resetLoginFlow()
        setState(s => ({...s, path }))

    }, [ resetLoginFlow ])

    // events
    useEffect(() => {
        app.events.add("LoginFlow.SignupPath.Complete", reset)
    }, [ reset ])

    return {
        ...state,
        reset,
        useSignupPath: () => setState(s => ({...s, path: LoginFlowPath.Signup})),
        useLoginPath: () => setState(s => ({...s, path: LoginFlowPath.Login})),
        useTeamPreviewPath: () => setState(s => ({...s, path: LoginFlowPath.TeamPreview})),
        useForgotPasswordPath: () => setState(s => ({...s, path: LoginFlowPath.ForgotPassword})),
        useResetPasswordPath: () => setState(s => ({...s, path: LoginFlowPath.ResetPassword})),
    }

}