import { useAppState } from "@/state/app/useAppState";
import { useEffect, useRef, useState, useMemo } from "react";
import { PageLoading } from "@/components/pure/PageLoading";
import { useTeamEventsData } from "@/state/data/useTeamEvents";
import styled from "styled-components";
import { MobileScheduleView } from "@/components/views/Schedule/MobileScheduleView";
import { DesktopScheduleView } from "@/components/views/Schedule/DesktopScheduleView";
import { Alert } from "@gamesheet/ui";
import { usePageState } from "@/state/page/usePageState";
import { GamesheetPage } from "@/state/page/GamesheetPage";
import { ExportAllScoreheetsModal } from "@/components/pure/ExportAllScoresheetsModal";
import { Resources, Actions } from "@gamesheet/tspkg-auth-client"
import { useUserState } from "@/state/user/useUserState";
import { useRecoilState, useResetRecoilState } from "recoil";
import { rawScheduledGameData } from "@/services/useScheduledGameService/atom.scheduledGameData";
import { rawEventData } from "@/services/useEventService/atom.eventData";
import { showPastEventsState } from "@/components/views/Schedule/atom.showPastEvents";
import { useLatestLineup } from "@/flows/LineupFlow/state/useLatestLineup";
import { useFlag } from "@unleash/proxy-client-react";
import { CalendarSyncModal } from "@/components/pure/CalendarSyncModal";
import { useTeamsData } from "@/state/data/useTeams";


export function TeamSchedulePage() {
    
    const app = useAppState();
    const user = useUserState();
    const teams = useTeamsData();
    const todayRef = useRef<HTMLDivElement | null>(null)
    const events = useTeamEventsData()
    const teamEvents = [...events.all(), ...events.events() ];
    const futureEvents = events.events().filter((event) => new Date(event.scheduledStartTime) >= new Date());
    const pastEvents = events.events().filter((event) => new Date(event.scheduledStartTime) < new Date());
    const next = events.nextGame
    const showLoading = !app.hasSelectedTeam || !app.loading.isComplete('events')
    const [showExportModal, setShowExportModal] = useState(false);
    const [showCalendarModal, setShowCalendarModal] = useState(false);
    const resetScheduledGameData = useResetRecoilState(rawScheduledGameData);
    const resetEventData = useResetRecoilState(rawEventData);
    const [ showPastEvents, setShowPastEvents ] = useRecoilState(showPastEventsState);
    const { latestLineup } = useLatestLineup(next?.id, app.selectedTeam);
    const isRemoteLineupsEnabled = useFlag('RemoteLineups') && user.GetCurrentTeamClaim();
    const staff = app.selectedTeamClaim?.role === 'staff';


    const Page = usePageState('team-schedule', {
        showActionsMenu: true,
        rememberScroll: false
    })

    const [  canSchedule, setCanSchedule ] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            const can = await user.CheckPermissions(Resources.SCHEDULED_GAMES, [Actions.CREATE]);
            const seasonCan = staff && teams.getSeasonTeams(app.selectedTeam)?.some((team) => team.season.manager_scheduling === true) || false;
            setCanSchedule(can || seasonCan);
        };
        fetchData();
    }, [JSON.stringify(user), app.inviteAccepted, JSON.stringify(teams), staff]);

    useEffect(() => {
        Page.clearButtons()

        staff && Page.addButton(() => {
            setShowExportModal(true);
            Page.closeActionMenu();
        }, 'Download All Scoresheets')

        staff && Page.addButton(() => {
            resetEventData();
            app.navigate(`/practice/new`)
            Page.closeActionMenu();
        }, '+ New Practice');

        staff && Page.addButton(() => {
            resetEventData();
            app.navigate(`/event/new`)
            Page.closeActionMenu();
        }, '+ New Event');

        Page.addButton(()=> {
            setShowPastEvents(!showPastEvents);
            Page.closeActionMenu();
        }, showPastEvents ? 'Hide Past Events' : 'Show Past Events')

        Page.addButton(() => {
            setShowCalendarModal(true);
            Page.closeActionMenu();
        }, 'Subscribe to Team Schedule')

        if (staff && canSchedule) {
            Page.addButton(() => {
                resetScheduledGameData();
                app.navigate("/games/new")
                Page.closeActionMenu();
            }, '+ New Scheduled Game')
        }
    }, [canSchedule, showPastEvents, staff]);

    // wait for events to load
    if (showLoading) {
        return <PageLoading />
    }

    let view: JSX.Element = <></>

    if (window.innerWidth < 1015) {
        view = <MobileScheduleView todayRef={todayRef} next={next} scheduledGames={[...events.scheduledGames(), ...futureEvents ]} completedGames={[...events.completedGames(), ...pastEvents ]} events={teamEvents} eventsChanged={events.changeSignal} latestLineup={latestLineup} isRemoteLineupsEnabled={isRemoteLineupsEnabled}/>
    }

    if (window.innerWidth >= 1015) {
        view = <DesktopScheduleView todayRef={todayRef} next={next} events={teamEvents} teamId={app.selectedTeam} eventsChanged={events.changeSignal} latestLineup={latestLineup} isRemoteLineupsEnabled={isRemoteLineupsEnabled} />
    }

    return <GamesheetPage $loading={Page.loading}>
        <>
            {showCalendarModal && (<CalendarSyncModal onClose={() => setShowCalendarModal(false)} />)}
            {showExportModal && (<ExportAllScoreheetsModal onClose={() => setShowExportModal(false)} numGames={events.numCompleted()} />)}
            {view}
        </>
    </GamesheetPage>
}