import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components"
import AddNewTeam from '@mui/icons-material/Add'
import { useSelectedTeamState } from "@/state/team/useSelectedTeamState";
import { useAppState } from "@/state/app/useAppState";
import { NavButton } from "@/components/pure/NavButton";
import { TeamData, useTeamsData } from "@/state/data/useTeams";
import { Button, Card, Logo } from "@gamesheet/ui";
import { useSeasonsData } from "@/state/data/useSeasonTeams";
import { TeamPreview } from "@/components/pure/TeamPreview";
import { useEditPrototeam } from "@/windows/editPrototeam/useEditPrototeam";
import editPencil from "../assets/images/pencil.svg";
import jersey from "../assets/images/dark_blank_jersey.svg"
import MenuIcon from '@mui/icons-material/Menu';
import { AcceptInvitationFlowState } from "@/flows/AcceptInvitationFlow/state/atom.AcceptInvitationFlowState";
import { useResetRecoilState } from "recoil";
import { useUserState } from "@/state/user/useUserState";
import navigateIcon from "@/assets/images/navigate-icon.png";

const StyledMobileUserBar = styled.div.attrs({
    className: "user-bar"
})`
display: flex;
align-items: center;
width: 100vw;
height: 60px;
background-color: ${props => props.theme.secondaryColor};
color: ${props => props.theme.textOnSecondaryColor};
position: fixed;
top: 0;
left: 0;
box-shadow: 0 0 10px 0 rgba(0,0,0,0.5);
z-index: 3301;

.logo-tabs {
    display: none;
}
`

const StyledDesktopUserBar = styled.div.attrs({
    className: "user-bar"
})`
width: 100vw;
margin-top: 32px;
height: 78px;
background-color: ${props => props.theme.secondaryColor};
color: ${props => props.theme.textOnSecondaryColor};
position: fixed;
top: 0;
left: 0;
box-shadow: 0 0 10px 0 rgba(0,0,0,0.5);
z-index: 100;

.logo-tabs {
    display: flex;
}

.selector {
    padding-left: 20px;
}

.inner-user-bar {
    max-width: 1280px;
    margin: 0 auto;
}
`

const InnerUserBar = styled.div.attrs({
    className: "inner-user-bar"
})`
width: 100vw;
display: flex;
justify-content: space-between;
align-items: center;
height: 78px;
`

const Left = styled.div.attrs({
    className: "left"
})`
display: flex;
justify-content: flex-start;
align-items: center;
`

const Right = styled.div.attrs({
    className: "right"
})`
display: flex;
justify-content: flex-end;
margin-right: 20px;
align-items: center;
`

const ActionButtonContainer = styled.div`
margin-right: 10px;
`

const Tab = styled.button.attrs({
    className: "tab"
})`
min-width: 60px;
width: 80px;
margin: 0 10px;
text-align: center;
font-size: 0.8rem;
z-index: 3301;
background-color: transparent;
border: none;

a { 
    color: ${props => props.theme.textOnSecondaryColor};
    text-decoration: none;
}

a.active, a:hover { 
    color: ${props => props.theme.primaryColor};
    text-decoration: none;
}
`

const Tabs = styled.div.attrs({
    className: "tabs"
})`
display: flex;
align-items: center;
z-index: 3301;
`

const StyledPrototeamSelection = styled.div.attrs({
    className: "prototeam-selection"
}) <{ $state: string, $desktopWidth?: number, $teamCount?: number }>`
display: flex;
flex-direction: column;
align-items: stretch;
justify-content: flex-start;
gap: 15px;

background-color: ${props => props.theme.secondaryColor};
bottom: ${props => props.$state === "open" ? "0" : "100vh"};
position: fixed;
transition: bottom 0.2s ease-in-out;
z-index: 3301;
overflow-y: scroll;
padding: 15px;
padding-top: 10px;

.card{
    cursor: pointer;
}

.team-selector {
    cursor: pointer;
    background-color: #FFF;
    border-radius: 4px;
    padding: 15px;

    display: flex;
    justify-content: space-around;
    align-items: center;
    /* display: grid;
    grid-template-columns: 60px 1fr; */
    column-gap: 15px;

    .team-logo {
        padding: 0;
        place-self: center center;
    }
}
`

const TeamEditContainer = styled.div.attrs({
    className: "team-edit-container"
})`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-left: 15px;
    text-align: center;
    cursor: pointer;
`


const DesktopPrototeamSelection = styled(StyledPrototeamSelection)<{ $teamCount: number }>`
left: calc((100vw - min(1280px, 100vw)) / 2);
height: ${props => `${(props.$teamCount * (85.59) + 85)}px`};
max-height: calc(100vh - 173px);
width: ${props => props.$desktopWidth ? props.$desktopWidth : "425px"};
max-width: 425px;
z-index: 3301;
top: ${props => props.$state === "open" ? "110px" : "-100vh"};
`

const MobilePrototeamSelection = styled(StyledPrototeamSelection)`
height: calc(100% - 60px);
width: 100vw;
z-index: 3301;
`

const TeamsContainer = styled.div<{ $mobile: boolean, $teamCount: number }>`
height: ${ props => !props.$mobile ? `calc(100% - 60px)`: `${(props.$teamCount * (85.59))}px` };
width: 100%;
overflow-y: scroll;

    .card-container:last-child {  
        margin-bottom: 0 !important; 
    }
`

type MenuContainerProps = {
    $open?: boolean;
}

const Overlay = styled.div<MenuContainerProps>`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    z-index: 3150;
    display: ${p => (p.$open ? "block" : "none")};
    pointer-events: ${p => (p.$open ? "auto" : "none")};
`;


export function UserBar() {

    const app = useAppState()
    const user = useUserState()
    const events = app.events
    const selectedTeam = useSelectedTeamState()
    const StyledUserBar = app.device.isMobile ? StyledMobileUserBar : StyledDesktopUserBar;
    const [selectorState, setSelectorState] = useState<string>("closed");
    const [menuOpen, setMenuState] = useState(false)
    const { teams, unmigratedTeams, getPlaysIn } = useTeamsData()
    const teamSelectorRef = useRef<HTMLDivElement>(null)
    const PrototeamSelection = app.layout.showMobile ? MobilePrototeamSelection : DesktopPrototeamSelection;
    const { open: openEditPrototeamModal, setEditPrototeamData } = useEditPrototeam()
    const resetAcceptInvitation = useResetRecoilState(AcceptInvitationFlowState)
    const clubTeamsCount = user.extraDetails.clubTeams.length

    const buttons = app.navigation.desktopItems();
    const selectableTeams = [...teams, ...unmigratedTeams];

    const toggleSelector = () => {
        selectorState == "closed" ? app.menu.open() : app.menu.close();
        let menuState = menuOpen === true ? false : true
        setMenuState(menuState)
        setSelectorState((current) => current === "closed" ? "open" : "closed")
    }

    const sendToDashboard = useCallback(async () => {
        const otp = await user.GetOtp()
        if (otp) {
            const url = `${app.config.apps.leagueDashboard}/associations?otp=${otp}`
            window.open(url);
        }
    }, [JSON.stringify(user), app.config.apps.leagueDashboard]) 

    const selectTeam = (team: TeamData) => {
        app.menu.close();
        if (team.seasonTeams) {
            app.setSelectedTeam(team.id);
            app.navigate("/games")
        } else {
            app.setTempSelectedTeam(team.title, team.logo);
            resetAcceptInvitation();
            app.navigate("/migrate?teamId=" + team.id);
        }
        setMenuState(false);
        setSelectorState("closed")
    }

    const editTeam = (e: React.MouseEvent<HTMLDivElement, MouseEvent>,team:TeamData) => {
        setEditPrototeamData(team)
        e.stopPropagation();
        events.handler('show-edit-prototeam')(e)
    }

    useEffect(() => events.add('show-edit-prototeam', openEditPrototeamModal), [openEditPrototeamModal])

    const actionButton = app.ui('actionTray').get();
    const haveScreenSpace = app.layout.showDesktop || app.layout.showTablet;


    return <>
        <StyledUserBar>

            <InnerUserBar>

                <Left>

                    <TeamPreview
                        ref={teamSelectorRef}
                        onClick={toggleSelector}
                        logo={app.tempSelectedTeam.logo !== "" ? app.tempSelectedTeam.logo : selectedTeam?.logo || ""}
                        title={app.tempSelectedTeam.title !== "" ? app.tempSelectedTeam.title : selectedTeam?.title || "No Active Team"}
                        subtitle={selectedTeam?.club || ""}
                        showSelector
                        selectorState={selectorState}
                        $stretch={app.device.isMobile}
                    />

                    {haveScreenSpace && <>
                        <div className="logo-tabs">
                            <Tabs>
                                {buttons.map((button, index) => (
                                    <Tab key={index}>
                                        <NavButton button={button} size="lg" />
                                    </Tab>
                                ))}
                            </Tabs>
                        </div>
                    </>}

                </Left>

                <Right>
                    {(haveScreenSpace && actionButton) && <>
                        <ActionButtonContainer>
                            {actionButton}
                        </ActionButtonContainer>
                    </>}
                </Right>

            </InnerUserBar>
        {/* {!haveScreenSpace && <MenuIcon fontSize="large" style={{cursor: "pointer", marginRight: "15px"}} onClick={() => events.trigger('toggle-more-menu')}/>} */}
        </StyledUserBar>

        {/* {haveScreenSpace && <Overlay $open={menuOpen} onClick={toggleSelector} />} */}
        <PrototeamSelection $state={selectorState} $desktopWidth={teamSelectorRef.current?.clientWidth} $teamCount={clubTeamsCount > 0 ? selectableTeams?.length + 1 : selectableTeams?.length}>
            <TeamsContainer $mobile={app.layout.showMobile} $teamCount={clubTeamsCount > 0 ? selectableTeams?.length + 1 : selectableTeams?.length}>
                {selectableTeams.map((team, index) => (
                    <Card.Card key={index} handleClick={()=>{selectTeam(team)}}>
                    <div> <Card.CardContentContainer header={team?.title || ""} child={getPlaysIn(team.id) || ""} imgUrl={team?.logo ? `${team?.logo}/96` : jersey}/></div>
                        <Card.CardIconContainer>
                            { team.claim?.role === 'staff' && <TeamEditContainer onClick={(e) => {
                                editTeam(e,team)
                            }}>
                                <img src={editPencil} alt="Edit" style={{width:"40px", height: "40px"}} />
                                <span style={{marginTop: "-10px", paddingBottom: "10px"}}>Edit</span>
                            </TeamEditContainer>}
                        </Card.CardIconContainer>
                    </Card.Card>
                    
                ))}
            </TeamsContainer>
            <Button style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={() => {
                app.navigate("invitation")
                setSelectorState("closed")
            }}>
                <AddNewTeam fontSize="small" />
                <span style={{ fontSize: '16px' }}>Add New Team</span>
            </Button>
            {clubTeamsCount !== 0 && <Button variant="inverted" onClick={sendToDashboard} style={{display: 'flex', alignItems: 'center', padding: '10px', cursor: 'pointer', width: '100%', textAlign: 'left' as 'left'}}>
                <img src={navigateIcon} alt="Icon" style={{height: '50px', width: '50px', marginRight: '20px', marginLeft: '10px'}} />
                <div style={{}}>
                    <div style={{fontWeight: '500', fontSize: '16px'}}>View My Other Teams</div>
                    <div style={{fontWeight: '400', fontSize: '12px', fontStyle: 'italic'}}>Switch to Admin Dashboard to view your {clubTeamsCount} other teams.</div>
                </div>
            </Button>}
        </PrototeamSelection>

    </>
}