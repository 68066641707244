import { useUserState } from "@/state/user/useUserState";
import { LoginStep } from "./step.login";
import { useLoginFlow } from "./state/useLoginFlow";
import { LoginFlowPath } from "./types";
import { SignupStep } from "./step.signup";
import { useCallback, useEffect, useState } from "react";
import { Button } from "@gamesheet/ui";
import { styled } from "styled-components";
import { UiTrays } from "@/layouts/Trays";
import { useAppState } from "@/state/app/useAppState";
import useQueryParams from "@/hooks/useQueryParams";
import { useLoginFlowService } from "./services/useLoginFlowService";
import { PageLoading } from "@/components/pure/PageLoading";
import { PreviewTeam } from "./step.PreviewTeam";

const MobileLoginFlowContainer = styled.div`
position: fixed;    
left: 16px;
right: 16px;
bottom: 16px;
top: 16px;
display: flex;
flex-direction: column;
/* justify-content: center; */
align-items: center;

.signup-step {
    height: calc(100% - 32px);
    overflow-y: scroll;
}
`

const DesktopFlowContainer = styled.div`
height: 100%;
.signup-step {
    width: 400px;
    max-height: calc(100% - 32px);
}
`

const LoginFlowButtons = styled.div`
display: flex;
flex-direction: column;
margin-top: 4px;
width: 100%;

gap: 4px;
position: relative;

.mobile-tray, .desktop-tray {
    position: relative;
    background: transparent;
    height: inherit;
    top: inherit;
    left: inherit;
    right: inherit;
    bottom: inherit;
    min-height: 0;
    padding: 0;

    display: flex;
    flex-direction: column;

    .inner {

        width: 100%;
        display: flex;
        gap: 4px;
        flex-direction: column-reverse;
        padding: 0;

        > div {
            width: 100%;
            float: none;
            padding: 0;
        }
    }

    .button {
        width: 100%;
        margin: 0;
    }
}

.button {
    width: 100%;
}
`

type LoginFlowButtonProps = {
    onClick: () => void
    label: string
}

function LoginFlowButton({ onClick, label }:LoginFlowButtonProps){
    return <Button 
        onClick={onClick} 
        type="button" 
        gs-style="solid" 
        variant="inverted"
        size="lg"
    >
            {label}
    </Button>
}

export function LoginFlow(){

    const app = useAppState()
    const user = useUserState()
    const flow = useLoginFlow(LoginFlowPath.Login)
    const LoginFlowContainer = app.layout.showMobile ? MobileLoginFlowContainer : DesktopFlowContainer
    const queryParams = useQueryParams();
    const { loadCodeDetails, loadingTeam, hasValidationError, validationError } = useLoginFlowService();
    const [ invitationCodeDetails, setInvitationCodeDetails ] = useState<any>(undefined);

    // useEffect(() => {

    //     const code = queryParams['invitation']
    //     const signedIn = queryParams['signedIn']
    //     console.log(signedIn)
    //     const getTeam = async () => {
    //         const codeDetails = await loadCodeDetails(code);
    //         if(codeDetails) {
    //             setInvitationCodeDetails(codeDetails);
    //             flow.useTeamPreviewPath();
    //         }
    //     }

    //     if (!signedIn && code && app.location.pathname === '/invitation') {
    //         getTeam();
    //     }
    // },[ JSON.stringify(queryParams), app.location.pathname ])

    const path = useCallback(() => {
        switch(flow.path){
            default:
            case LoginFlowPath.Login: return <LoginStep user={user} onSignup={flow.useSignupPath}/>
            case LoginFlowPath.Signup: return <SignupStep />
            case LoginFlowPath.ForgotPassword: return <></>
            case LoginFlowPath.ResetPassword: return <></>
            case LoginFlowPath.TeamPreview: return <PreviewTeam logo={invitationCodeDetails?.team?.logo} title={invitationCodeDetails?.team?.title} name={invitationCodeDetails?.invitation?.name} inviteType={invitationCodeDetails?.invitation?.claim?.role} code={invitationCodeDetails?.invitation?.id} onBack={flow.useLoginPath} onAccept={ () => {}} />
            // case LoginFlowPath.TeamPreview: return <PreviewTeam logo={""} title={""} name={""} inviteType={"staff"} register={false} onBack={() => {}} onAccept={ () => {}} />
            // case LoginFlowPath.TeamPreview: return <></>
        }
    }, [ flow.path, user ])

    const buttons = useCallback(() => {
        switch(flow.path){
            case LoginFlowPath.Login: return <>
                {/* <LoginFlowButton onClick={flow.useForgotPasswordPath} label="Forgot Password" />
                <LoginFlowButton onClick={flow.useResetPasswordPath} label="Reset Password" /> */}
            </>

            case LoginFlowPath.Signup: return <>
                <LoginFlowButton onClick={flow.reset} label="Cancel" />
            </>

            case LoginFlowPath.ForgotPassword: return <></>
            case LoginFlowPath.ResetPassword: return <></>
        }
    }, [ flow.path ])

    if(loadingTeam){
        return <PageLoading />
    }

    return <LoginFlowContainer>
        { path()}
        <LoginFlowButtons>
            <UiTrays />
            { buttons() }
        </LoginFlowButtons>
    </LoginFlowContainer>

}