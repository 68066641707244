import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Modal, Card, Alert, Button, QRCard } from "@gamesheet/ui";
import { BreakPoints } from "@/libs/breakpoints";
import { useSelectedTeamState } from "@/state/team/useSelectedTeamState";
import { InviteUserForm, InviteUserFormState } from "@/forms/InviteUserForm";
import { MemberClaim } from "@/services/useMemberService/types.ts/member";
import primaryBlack from "./components/primaryBlack.png";
import { useAppState } from "@/state/app/useAppState";

const StyledView = styled.div`
  width: 100%;

  @media screen and (min-width: ${BreakPoints.lg}) {
    padding-top: 0;
  }
`;

const SmallDivider = styled.div`
  border: 1px solid #d7d7d8;
  margin-bottom: 1rem;
  padding-left: -1rem;
  width: 100%;
`;

const Divider = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  margin: 1rem 0;
  font-size: 20px;

  &::before,
  &::after {
    content: '';
    flex: 1;
    border-top: 3px solid #d7d7d8;
  }

  &:not(:empty)::before {
    margin-right: 0.5em;
  }

  &:not(:empty)::after {
    margin-left: 0.5em;
  }
`;

const InfoText = styled.div`
  color: #000;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 1rem 0;
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .button {
    font-size: 1rem !important;
    font-weight: 500 !important;
  }
`;

const ContentContainer = styled.div`

`;

type InviteStaffProps = {
  onBack: () => void;
  onSubmit: (emails: string, type: MemberClaim) => void;
  inviteCode: string;
  userEmail: string;
};

export const InviteStaff: React.FC<InviteStaffProps> = ({ onBack, onSubmit, inviteCode, userEmail }) => {

  const app = useAppState();
  const [isValid, setIsValid] = useState(false);
  const [formData, setFormData] = useState<InviteUserFormState>();
  const handleSubmit = () => { 
    const claim: MemberClaim = {role: 'staff'};
    if (formData) {
      onSubmit(formData.emails.value, claim);
    }
  }

  const baseUrl = app.config.name === 'Production' ? 'https://teams.gamesheet.app' : (app.config.name === 'QA' ? 'https://qa1.teams.gamesheet.app' : 'https://dev.teams.gamesheet.app');

  return (
    <StyledView>
      { /* We are removing the ability for a staff member to be invited via QR code <div style={{marginBottom: '1rem'}}>
        <QRCard 
          url={`${baseUrl}/invitation?invitation=` + inviteCode} 
          logo={primaryBlack}
        />
      </div>

      <Divider>OR</ Divider> */}

      <ContentContainer>
          <InfoText>Coaches, managers and others who hold key responsibilities within the team. </InfoText>
          <InviteUserForm onSubmit={handleSubmit} setIsValid={setIsValid} setFormData={setFormData} email={userEmail} />
          <InfoText>To send multiple invitations, separate additional emails by commas.</InfoText>
        </ContentContainer>

        <SmallDivider />

        <FooterContainer>
          <Button variant="inverted" label="Cancel" onClick={() => onBack()}>
            Cancel
          </Button>
          {isValid && formData ? <Button onClick={handleSubmit}>Send Invitation</Button> : <Button state="disabled" variant="inverted" label="Send Invitation" />}
        </FooterContainer>
    </StyledView>
  );
};
