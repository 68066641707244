export type LoginFlowType = {
    path: LoginFlowPath;
}

export enum LoginFlowPath {
    Login = 'loginPath',
    ForgotPassword = 'forgotPasswordPath',
    ResetPassword = 'resetPasswordPath',
    Signup = 'signupPath',
    TeamPreview = 'teamPreviewPath',
}