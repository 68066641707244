import { atom } from "recoil";
import { AcceptInvitationFlowStep as Step, AcceptInvitationFlowType } from "@/flows/AcceptInvitationFlow/types";

export const AcceptInvitationFlowState = atom<AcceptInvitationFlowType>({
    key: 'AcceptInvitationFlowState',
    default: {
        loading: false,
        invitationCode: "",
        fInvitation: undefined,
        schedulerInvitation: false,
        indirectStaff: false,
        inviteSource: "",
        inviterName: "",
        invitationTeam: undefined,
        invitationType: {role: "follower"},
        teamRelationship: undefined,
        phoneNumber: "",
        rawMergedData: undefined,
        prototeamOptions: {
            strategy: "default",
            linkedTeam: undefined,
        },
        error: "",
        mergeError: "",
        step: Step.InsertInvitationCode,
        paths: {
            newUserPath: [
                Step.InsertInvitationCode,
                Step.PreviewTeam,
                Step.PreviewRoster,
                Step.TeamRelationship,
                Step.SwitchToDashboard,
                Step.RegisterNewAccount,
                Step.PhoneNumber,
            ],
            existingUserPath: [
                Step.InsertInvitationCode,
                Step.PreviewTeam,
                Step.PreviewRoster,
                Step.TeamRelationship,
                Step.SwitchToDashboard,
                Step.PhoneNumber,
                Step.PasswordConfirmation,
                Step.PrototeamOptions,
                Step.SelectedOptionSummary,
                Step.Success,
            ],
            migrateTeamPath: [
                Step.TeamRelationship,
                Step.SwitchToDashboard,
                Step.PhoneNumber,
            ],
        }
    }
})