import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import IframeWithOtp from "@/components/utils/IframeWithOtp";
import { useAppState } from "@/state/app/useAppState";
import { useUserState } from "@/state/user/useUserState";
import { TeamEvent, useTeamGame } from "@/state/data/useTeamEvents";
import { usePageState } from "@/state/page/usePageState";
import { useScoresheetService } from "@/services/useScoresheetService";
import { Resources, Actions } from "@gamesheet/tspkg-auth-client"
import { useResetRecoilState } from "recoil";
import { rawScheduledGameData } from "@/services/useScheduledGameService/atom.scheduledGameData";
import { firestore } from "@/libs/firebase";
import { doc, getDoc } from "firebase/firestore";
import { useFlag } from "@unleash/proxy-client-react";
import { useTeamsData } from "@/state/data/useTeams";

const StyledFloatingMenu = styled.div`
display:flex;
flex-direction:column;
row-gap: 5px;
align-items: flex-end;
z-index: 1000;

.button {
    display: ilnine-block;
}
`

type GameStatus = {
  status: string
  visitor: {
    id: string
    prototeam: {
      id: string
    }
  }
  home: {
    id: string
    prototeam: {
      id: string
    }
  }
}

async function getGameStatus(gameId: string | undefined) {
  if (!gameId) {
    return
  }

  const docRef = doc(firestore, "games", gameId);

  const game = await getDoc(docRef);

  if (game.exists()) {
    const scheduledGamesData: GameStatus = game.data() as GameStatus;

    return scheduledGamesData
  }
}

export function SchedulePageBoxScore() {

  const app = useAppState();
  const user = useUserState();
  const teams = useTeamsData();
  const { fetchScoresheet } = useScoresheetService()
  const { seasonId, gameId } = useParams();
  const game: TeamEvent = useTeamGame(gameId) as TeamEvent
  const [ canEditSchedule, setCanEditSchedule ] = useState(false);
  const [ showActions, setShowActions ] = useState(false);
  const [ sentGameRequest, setSentGameRequest ] = useState(false);
  const resetScheduledGameData = useResetRecoilState(rawScheduledGameData);
  const isRemoteLineupsEnabled = useFlag('RemoteLineups') && user.GetCurrentTeamClaim();
  const staff = app.selectedTeamClaim?.role === 'staff';

  useEffect(() => {
    const fetchData = async () => {
        const can = await user.CheckPermissions(Resources.SCHEDULED_GAMES, [Actions.UPDATE]);
        const seasonCan = staff && teams.getSeasonTeams(app.selectedTeam)?.some((team) => team.season.manager_scheduling === true) || false;
        setCanEditSchedule(can || seasonCan);
    };
    fetchData();
  }, [JSON.stringify(user), app.inviteAccepted, JSON.stringify(teams), staff]);

  useEffect(() => {
    const played = ["completed", "final"].includes(game?.status)
    setShowActions((played || (!played && canEditSchedule) || (!played && isRemoteLineupsEnabled)) && app.selectedTeamClaim?.role === 'staff')
  }, [ canEditSchedule, game?.status, isRemoteLineupsEnabled])

  const Page = usePageState(`boxscore-${game?.id}`, {
    showActionsMenu: showActions,
    showBackButton: true
  })

  useEffect(() => {
    const handleActionButtonContents = (action: 'download' | 'edit') => {
      if (action === 'download') {
        return () => {
          if (gameId) {
            fetchScoresheet(gameId);
          }
        }
      } else {
        return () => {
          resetScheduledGameData();
          app.navigate(`/games/${gameId}`);
          Page.closeActionMenu();
        }
      }
    };
  
    if (!user.tokens.access || !gameId) return;

    if (!game?.status) {
      if (sentGameRequest) return;
      setSentGameRequest(true);
      getGameStatus(gameId)
        .then((gameStatusResult) => {
          if (gameStatusResult) {
            Page.clearButtons();
            const isCompletedOrFinal = ["completed", "final"].includes(gameStatusResult.status);
            Page.addButton(handleActionButtonContents(isCompletedOrFinal ? 'download' : 'edit'), isCompletedOrFinal ? 'Download Scoresheet' : '+ Edit Scheduled Game');

            const homeMatches = app.teams.teamData.some((team: { id: string }) => team.id === gameStatusResult.home.prototeam.id);
            const visitorMatches = app.teams.teamData.some((team: { id: string }) => team.id === gameStatusResult.visitor.prototeam.id);

            if (homeMatches) {
              app.setSelectedTeam(gameStatusResult.home.prototeam.id);
            } else if (visitorMatches) {
              app.setSelectedTeam(gameStatusResult.visitor.prototeam.id);
            }
          }
        })
        .catch((error) => {
          console.error("Error:", error.message);
        });
    } else {
      Page.clearButtons();
      const isCompletedOrFinal = ["completed", "final"].includes(game?.status);
      canEditSchedule && Page.addButton(handleActionButtonContents(isCompletedOrFinal ? 'download' : 'edit'), isCompletedOrFinal ? 'Download Scoresheet' : '+ Edit Scheduled Game');
      isRemoteLineupsEnabled && Page.addButton(()=> { app.navigate(`/teams/${app.selectedTeam}/games/${gameId}/lineups`)}, "Set Lineup")
    }
  }, [user.tokens.access, sentGameRequest, gameId, game?.status, canEditSchedule, isRemoteLineupsEnabled]);

  return <>
    { user.tokens.access && <IframeWithOtp id="boxscore" src={`${app.config.apps.statsWidget}/seasons/${seasonId}/games/${gameId}?configuration[logo]=false&configuration[navigation]=false&configuration[links]=true`} /> }
    { !user.tokens.access  && <iframe id="boxscore" src={`${app.config.apps.statsWidget}/seasons/${seasonId}/games/${gameId}?configuration[logo]=false&configuration[navigation]=false&configuration[links]=true`} />}
  </>
}
