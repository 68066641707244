import { useCallback, useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import { useAppState } from "@/state/app/useAppState";
import { useUserState } from "@/state/user/useUserState";
import { removeMember } from "@/services/useMemberService/members/func.removeMember";

type LeavePrototeamModal = {
  loading: boolean;
  isOpen: boolean;
};

export const leavePrototeamModalState = atom<LeavePrototeamModal>({
  key: "leavePrototeamModalState",
  default: {
    loading: false,
    isOpen: false,
  },
});

export function useLeavePrototeam() {
  const app = useAppState();
  const user = useUserState();
  const [{ loading, isOpen }, setState] = useRecoilState(leavePrototeamModalState);

  const open = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isOpen: true,
    }));

    document.body.style.overflow = "auto";
  }, [setState]);

  const close = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isOpen: false,
    }));

    document.body.style.overflow = "auto";
  }, [setState]);

  const setLoading = useCallback((loading: boolean) => {
    setState((prev) => ({
      ...prev,
      isOpen: !loading ? false : prev.isOpen,
      loading,
    }));
  }, [setState]);

  const onRemoveTeam = async (id: string) => {
    const lastTeam = app.teams.count === 1
    setLoading(true);
    if (app.selectedTeamClaim?.role === 'staff') {
      const url = `${app.config.gateways.eventLog}/v5/users/${user.id}/remove-prototeam`;
      const headers = new Headers();
      user.isLoggedIn && headers.set("Authorization", `Bearer ${user.tokens.access}`);

      const requestData = {
        attributes: {
          event: "remove-prototeam",
          schema: "user-prototeam",
          version: "v1",
        },
        data: {
          userId: user.id,
          prototeamId: id,
        },
      };

      try {
        const response = await fetch(url, {
          method: "POST",
          headers,
          body: JSON.stringify(requestData),
        });

        if (response.ok) {
          setState((prev) => ({
            ...prev,
            loading: false,
            isOpen: false,
          }));
        } else {
          setLoading(false);
          console.error("Error while updating team data:", response);
        }
      } catch (error) {
        setLoading(false);
        console.error("Error while updating team data:", error);
      }
    }
    if (user.id) {
      await removeMember(id, user.id);
      await user.Expire();
      setLoading(false);
    }
    if (lastTeam) {
      window.location.reload();
    }
  };

  return {
    loading,
    open,
    close,
    isOpen,
    onRemoveTeam
  };
}
