import { Button, FormWrapper } from "@gamesheet/ui";
import { TextInput } from "./components/TextInput";
import { FormEvent, FormEventHandler, forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import { FormFieldType, FormsState, NewFormField, useCreateForm } from "@/forms/hooks/useCreateForm";
import { PasswordInput } from "./components/PasswordInput";
import { styled } from "styled-components";
import { PageLoading } from "@/components/pure/PageLoading";
import { ErrorCard } from "@/components/pure/EntityCard/MobileEntityCard";
import { parsePhoneNumber, getCountries, getCountryCallingCode, isValidPhoneNumber } from "libphonenumber-js";
import { count } from "console";
import { on } from "events";

const StyledSignupForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 16px;
`

type SignupFormProps = {
    onSubmit: (data:SignupFormState) => void;
    needPhoneNumber: boolean;
    setValidNumber: (valid: boolean) => void;
    error?:string;
}

export type SignupFormState = {
    firstName: FormFieldType<string>;
    lastName: FormFieldType<string>;
    email: FormFieldType<string>;
    password: FormFieldType<string>;
    phoneNumber: FormFieldType<string>;
}

export const emailRegex = /^[\w-\+\.]+@([\w-]+\.)+[\w-]{2,4}$/;

export const SignupForm = forwardRef(({ onSubmit, error, needPhoneNumber, setValidNumber }:SignupFormProps, ref) => {

    // Get and format calling code options
    const countries = getCountries();
    const countryCodeOptions = countries.map(country => ({
        code: country,
        callingCode: `+${getCountryCallingCode(country)}`
    }));

    const _countryCodeOptions = countryCodeOptions.reduce((unique, current) => {
        if (current.callingCode !== '+1' && !unique.some(item => item.callingCode === current.callingCode)) {
            unique.push(current);
        }
        return unique;
    }, [] as { code: string; callingCode: string }[]);

    _countryCodeOptions.unshift({ code: 'US', callingCode: '+1' });
    _countryCodeOptions.unshift({ code: 'CA', callingCode: '+1' });

    _countryCodeOptions.sort((a, b) => a.callingCode.localeCompare(b.callingCode));

    // Create signup form
    const { state: formState, onChange, setFormsState } = useCreateForm('signup', {
        firstName: NewFormField('', /.{2,}/, 'First Name must be at least 2 characters'),
        lastName: NewFormField('', /.{2,}/, 'Last Name must be at least 2 characters'),
        email: NewFormField('', emailRegex, 'Email must be valid'),
        password: NewFormField('', /.{8,}/, 'Password must be at least 8 characters'),
        phoneNumber: NewFormField('', /.*/, 'Please enter a valid phone number'),
    })
    const [ countryCode, setCountryCode ] = useState('+1');
    const [ phoneNumber, setPhoneNumber ] = useState('');
    const [ phoneError, setPhoneError ] = useState(false);
    const [ phoneFocused, setPhoneFocused ] = useState(false);

    const handleSubmit = useCallback((e:FormEvent<HTMLFormElement>) => {
        e && e.preventDefault();
        
        for(const field of Object.values(formState)){
            if(!field.value.match(field.test)){
                return;
            }
        }
        
        onSubmit(formState);
    }, [ onSubmit, formState ])

    const handlePhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPhoneNumber(e.target.value);
    }

    const handleCountryCode = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setCountryCode(e.target.value);
    }

    useEffect(() => {  
        const validNumber = isValidPhoneNumber(countryCode + phoneNumber);
        setPhoneError(!validNumber);
        setValidNumber(validNumber);
        if (validNumber) {
            const number = parsePhoneNumber(countryCode + phoneNumber);
            if (setFormsState) {
                setFormsState((formState: any) => ({ ...formState, signup: { initialState: { ...formState?.signup.initialState }, currentState: {
                    ...formState?.signup.currentState,
                    phoneNumber: { ...formState?.signup.currentState.phoneNumber, value: number.number.toString(), valid: validNumber },
                }, }, }));
            }
        } else {
            if (setFormsState) {
                setFormsState((formState: any) => ({ ...formState, signup: { initialState: { ...formState?.signup.initialState }, currentState: {
                    ...formState?.signup.currentState,
                    phoneNumber: { ...formState?.signup.currentState.phoneNumber, value: countryCode + phoneNumber, valid: validNumber },
                }, }, }));
            }
        }
    }, [phoneNumber, countryCode, JSON.stringify(formState)])

    useImperativeHandle(ref, () => {
        return { 
            submitForm: handleSubmit,
            loading: !formState,
        }
    }, [ handleSubmit, formState ])

    if(!formState){
        return <PageLoading />
    }

    return <FormWrapper>
        <StyledSignupForm onSubmit={handleSubmit}>
            { error && <ErrorCard title="Email taken" message={error}/> }
            <TextInput state={formState.firstName} name="firstName" label="First Name" onChange={onChange} />
            <TextInput state={formState.lastName} name="lastName" label="Last Name" onChange={onChange} />
            <TextInput state={formState.email} name="signup-email" label="Email" onChange={onChange} autocomplete={false} />
            <PasswordInput state={formState.password} name="signup-password" label="Password" onChange={onChange} autocomplete={false} />
            {needPhoneNumber && (
                <>
                    <div style={{ display: "flex", gap: "8px", width: '100%', height: '58px' }} className="form-floating">
                        <select
                            name="countryCode"
                            value={countryCode}
                            onChange={handleCountryCode}
                            style={{ width: '120px', maxWidth: "120px" }}
                        >
                            {_countryCodeOptions.map((option) => (
                                <option key={option.code} value={option.callingCode}>
                                    {option.callingCode} ({option.code})
                                </option>
                            ))}
                        </select>
                        <div style={{width: '100%', height: '58px'}}>
                        <input
                            required
                            type={"text"}
                            name={"phoneNumber"}
                            id={"phoneNumber"}
                            className="form-floating"
                            placeholder={"Phone Number"}
                            onChange={handlePhoneNumber} 
                            value={phoneNumber}
                            role={"presentation"}
                            autoComplete={"off"}
                            style={{ width: '100%', height: '58px', borderRadius: '4px' }}
                            onFocus={() => setPhoneFocused(false)}
                            onBlur={() => setPhoneFocused(true)}
                        />
                        </div>
                    </div>
                    { phoneFocused && phoneError && <span style={{fontSize:'15.75px', color:'red', marginTop: '-12px'}}>Phone number is invalid</span>}
                </>
            )}
            

        </StyledSignupForm>
    </FormWrapper>

})