import { signOut, getIdToken, User } from "firebase/auth"
import { useEffect, useState } from "react"
import { useRecoilState, useRecoilValue } from "recoil"
import { useAppState } from "@/state/app/useAppState"
import { firebaseUserState } from "./useSetFirebaseAuth"
import { userState } from "./useUserState"

import { auth } from "@/libs/firebase"

const TOKEN_REFRESH_INTERVAL = 1000 * 60 * 5 // 5 minutes

export function useSetGameSheetUserState() {

    const app = useAppState()
    const firebaseUser = useRecoilValue(firebaseUserState)
    const [user, setUser] = useRecoilState(userState)

    // local timeouts
    const [timeout, _setTimeoutToken] = useState<NodeJS.Timeout | undefined>()
    const setTimeoutToken = (_timeout: NodeJS.Timeout) => {
        timeout && clearTimeout(_timeout)
        _setTimeoutToken(_timeout)
    }

    const waitAndRefreshTokens= (refresh:string) => {
        
        const timeoutToken = setTimeout(() => {
            refreshUserTokens(refresh)
            refreshTokensWithFirebase();
        }, TOKEN_REFRESH_INTERVAL); // 5 minutes
        setTimeoutToken(timeoutToken)

    }

    const getUserTokens = async (firebaseUser: User) => {

        try {

            // get new tokens
            const idToken = await getIdToken(firebaseUser)
            const response = await fetch(`${app.config.gateways.auth}/auth/v4/tokens`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${idToken}`
                }
            }).then(response => response.json())
            setUser({
                ...user,
                tokens: {
                    access: response.access,
                    refresh: response.refresh,
                    roles: response.roles,
                }
            })

            waitAndRefreshTokens(response.refresh)

        } catch (error) {

            signOut(auth)

        }

    }

    const refreshTokensWithFirebase = async () => {

        if (firebaseUser) {
            try {
                const idToken = await firebaseUser.getIdToken(true); // Force refresh
                return idToken;
            } catch (error) {
                console.error("Error refreshing token:", error);
            }
        } else {
            console.log("No user is signed in.");
        }

    }

    const refreshUserTokens = async (refresh: string) => {

        try {

            timeout && clearTimeout(timeout)
            const response = await fetch(`${app.config.gateways.auth}/auth/v4/refresh`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${refresh}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({})
            })
            .then(response => response.json())

            setUser({
                ...user,
                tokens: {
                    access: response.access,
                    refresh, // don't update this!
                    roles: response.roles,
                }
            })

            waitAndRefreshTokens(refresh)

        } catch(error) {

            signOut(auth)

        }
        
        
    }

    useEffect(() => {
        // don't get an idToken if there is no user
        if (!firebaseUser) {

            // this is important because the refresh tokens are chained
            timeout && clearTimeout(timeout)

            setUser({
                ...user,
                tokens: {
                    access: "",
                    refresh: "",
                    roles: "",
                }
            })

            app.setAcceptedInvite(false);

        } else {
            getUserTokens(firebaseUser);
            app.setAcceptedInvite(false);
        }

    }, [JSON.stringify(firebaseUser), app.inviteAccepted])

}