import { useCallback, useEffect, useState } from "react"
import styled from "styled-components";

import { useAppState } from "@/state/app/useAppState"
import { Menu } from "@gamesheet/ui"
import { useUserState } from "@/state/user/useUserState";
import { useSeasonsData } from "@/state/data/useSeasonTeams";
import { useIpadApiKeys } from "@/windows/ipadApiKeys/useIpadApiKeys";
import { useEditEmailNotifications } from "@/windows/editEmailNotifications/useEditEmailNotifications";
import { useInviteUserService } from "@/flows/InviteUserFlow/services/useInviteUserService";
import { useLeavePrototeam } from "@/windows/leavePrototeam/useLeavePrototeam";

type MenuContainerProps = {
    $open?: boolean;
}

const MobileMenuContainer = styled.div<MenuContainerProps>`
z-index: 3300;

.more-menu {
    bottom: ${p => p.$open ? '100px' : '-450px'};
    z-index: ${p => p.$open ? '3400' : '900'};
    transition: ${p => p.$open ? 'bottom 1s ease, z-index 1.05s step-end' : 'bottom 1s ease, z-index step-end'};
    padding-bottom:30px;
    background:
    linear-gradient(
        45deg,
        rgba(255, 255, 255, 0) 11%,
        rgba(255, 255, 255, 1) 11%
    ),
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 94%,
      rgba(0, 0, 0, 0) 94%
    ),
    linear-gradient(
      90deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 28%,
      rgba(255, 255, 255, 1) 28%,
      rgba(255, 255, 255, 1) 100%
    );
}
`

const DesktopMenuContainer = styled.div<MenuContainerProps>`
z-index: 3300;

.more-menu {
    top: ${p => p.$open ? '32px' : '-450px'};
    z-index: ${p => p.$open ? '3400' : '300'};
    transition: ${p => p.$open ? 'top 1s ease, z-index 1.05s step-end' : 'top 1s ease, z-index step-end'};
    right: calc((100vw - min(1280px, 100vw)) / 2);
    background: transparent;
}
`

const StyledMoreMenu = styled.div.attrs({
    className: "more-menu"
})`
position:fixed;
right: 0;
text-align:left;
background-color:#FFF;
z-index: 3200;

box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);

.menu-subtitle {
    color: #00000060;
}
`

const Overlay = styled.div<MenuContainerProps>`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0);
    z-index: 3150;
    display: ${p => (p.$open ? "block" : "none")};
    pointer-events: ${p => (p.$open ? "auto" : "none")};
`;

export function MoreMenu() {

    const app = useAppState()
    const events = app.events
    const user = useUserState()
    const seasonTeams = useSeasonsData()
    const { open: openIpadKeysModal } = useIpadApiKeys()
    const { open: openEditEmailNotificationsModal } = useEditEmailNotifications()
    const { open: openInviteUserModal } = useInviteUserService()
    const { open: openLeaveTeamModal } = useLeavePrototeam()
    const staff = app.selectedTeamClaim?.role === 'staff';

    const MenuContainer = app.layout.showMobile ? MobileMenuContainer : DesktopMenuContainer
    const [moreMenuOpen, setMoreMenuState] = useState(false)

    const toggleMoreMenu = useCallback(() => {
        moreMenuOpen ? app.menu.close() : app.menu.open()
        setMoreMenuState(!moreMenuOpen)
    }, [moreMenuOpen])

    // register event listeners
    useEffect(() => events.add('close-more-menu', () => moreMenuOpen && setMoreMenuState(false)), [moreMenuOpen, setMoreMenuState])
    useEffect(() => events.add('toggle-more-menu', toggleMoreMenu), [toggleMoreMenu])
    useEffect(() => events.add('show-ipad-keys', openIpadKeysModal), [openIpadKeysModal])
    useEffect(() => events.add('show-edit-email-notifications', openEditEmailNotificationsModal), [openEditEmailNotificationsModal])
    useEffect(() => events.add('show-leave-team', openLeaveTeamModal), [openLeaveTeamModal])

    return (<>

        <Overlay $open={moreMenuOpen} onClick={events.handler('close-more-menu')} />
        <MenuContainer $open={moreMenuOpen}>
            <StyledMoreMenu>
                <Menu.Container>
                    <Menu.Section label="">
                        { staff && <Menu.Item label="View Scoring Access Keys" onClick={(e) => {
                            toggleMoreMenu()
                            events.handler('show-ipad-keys')(e)
                        }} />}
                        <Menu.Item label="Invite Parents/Players" onClick={(e) => {
                            toggleMoreMenu()
                            openInviteUserModal(false)
                        }} />
                        { staff && <Menu.Item label="Invite Staff" onClick={(e) => {
                            toggleMoreMenu()
                            openInviteUserModal(true)
                        }} />}
                        { staff && <Menu.Item label="Manage Users" onClick={app.navigateOnClick("users/manage")} /> }
                    </ Menu.Section>
                    <Menu.Section label="My Account">
                        <Menu.Item label="Edit Profile" onClick={app.navigateOnClick("myaccount/profile")} />
                        { staff && <Menu.Item label="Email Notifications" onClick={(e)=> {
                            toggleMoreMenu()
                            events.handler('show-edit-email-notifications')(e)
                        }} />}
                        <Menu.Item label="Accept Invitation" onClick={app.navigateOnClick("invitation")} />
                        <Menu.Item label="Log Out" onClick={() => {
                            toggleMoreMenu()
                            user.SignOut()
                        }} />
                    </Menu.Section>
                    <Menu.Section label="">
                        <Menu.Item label="Help and Support" onClick={() => {window.location.href = ('https://help.gamesheet.app/')}} />
                    </Menu.Section>
                    <Menu.Section label="">
                        <Menu.Item label="Leave Team" onClick={(e) => {
                            toggleMoreMenu()
                            events.handler('show-leave-team')(e)
                        }} />
                    </Menu.Section>
                </Menu.Container>
            </StyledMoreMenu>
        </MenuContainer>
    </>)

}