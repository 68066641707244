import { Button, Title } from "@gamesheet/ui";
import { useEffect, useMemo } from "react";
import { useAppState } from "@/state/app/useAppState";
import { styled } from "styled-components";
import darkBlankJersey from "@/assets/images/dark_blank_jersey.svg";
import { dark } from "@mui/material/styles/createPalette";

const DesktopTeamPreview = styled.div`
    
    font-size: 18px;
    line-height: 23.7px;

    .header {
        font-weight: 700;
        font-size: 24px;
        text-align: center;
        justify-content: center;
    }

    .body {
        font-weight: 400;
        font-size: 20px;
        line-height: 22.75px;
        padding-top: 20px;
    }
`

const MobileTeamPreview = styled.div`

    font-size: 18px;
    line-height: 23.7px;

    .header {
        font-weight: 700;
        font-size: 24px;
        text-align: center;
        justify-content: center;
    }

    .body {
        font-weight: 400;
        font-size: 20px;
        line-height: 22.75px;
        padding-top: 20px;
    }

    .ul {
        list-style-type: disc;
        padding-left: 40px;
        margin-top: 20px;
        font-size: 10px;

        .li {
            margin-bottom: 10px;
            font-size: 10px;
        }
    }
`

const MobileAcceptInvitationFlow = styled.div`
    background-size: cover;
    background-position: right;
    background-attachment: fixed;
    background-color: #fff;
    min-height: calc(100vh - 32px);
    padding: 16px;
    border-radius: 16px;
`

const DesktopAcceptInvitationFlow = styled.div`
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    background-size: cover;
    background-position: right;
    background-attachment: fixed;
    background-color: #fff;
    border-radius: 16px;
    padding: 16px;
`

const StyledImage = styled.img`
  display: block;
  margin: 20px auto;
  max-width: 100%;
  height: auto;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2), 0 -5px 5px rgba(0, 0, 0, 0.2), 5px 0 5px rgba(0, 0, 0, 0.2), -5px 0 5px rgba(0, 0, 0, 0.2);
`;

type PreviewTeamProps = {
    logo: string;
    title: string;
    name: string;
    inviteType: string;
    code: string;
    onAccept: () => void;
    onBack: () => void;
}

export function PreviewTeam({ logo, title, name, inviteType, code, onBack, onAccept }:PreviewTeamProps){

    const app = useAppState()
    const StyledTeamPreview = (app.layout.showTablet || app.layout.showDesktop) ? DesktopTeamPreview : MobileTeamPreview;
    const StyledAcceptInvitationFlow = (app.layout.showTablet || app.layout.showDesktop) ? DesktopAcceptInvitationFlow : MobileAcceptInvitationFlow
    
    const startInvitationFlow = () => {
        app.navigate(`/invitation?invitation=${code}&signedIn=true`);
        onBack();
    }

    // const BtnBack = useMemo(() => <Button type="button" size="lg" style={register ? {} : {marginLeft: '16px', height: '36px', marginBottom: '-100px'}} variant={'inverted'} onClick={onBack}>Back</Button>, [ onBack ])
    // useEffect(() => app.ui('leftTray').set(BtnBack, app.layout.showMobile), [ BtnBack, app.layout.showMobile ])

    // const BtnNext = useMemo(() => <Button type="button" size="lg" style={register ? {} : {marginRight: '16px', height: '36px', marginBottom: '-100px'}} onClick={onAccept}>Continue</Button>, [ onAccept ])
    // useEffect(() => app.ui('rightTray').set(BtnNext, app.layout.showMobile), [ BtnNext, app.layout.showMobile ])

    return <StyledAcceptInvitationFlow>
        <StyledTeamPreview>

            { name !== "" &&  <div className="body">{name} has invited you to join their team:</div>}
            { name === "" && <div className="body"> You have been invited to the team:</div> } 
            <StyledImage src={logo == "" ? darkBlankJersey : logo+'/256'} style={{ display: 'block', margin: '20px auto', maxWidth: '100%', height: 'auto' }} />
            <div className="header">{title}</div>
            <div className="body" style={{fontSize: '18px'}}>As a <b>{inviteType == 'staff' ? 'Staff' : 'Follower'}</b> member you'll be able to:</div>
            <div style={{ fontSize: '16px' }}>
                { inviteType == 'staff' && <ul>
                    <li>Manage Your Team’s Roster</li>
                    <li>Schedule Practices, Events, and Games</li>
                    <li>Track Your Position in the Standings</li>
                    <li>View Player Stats</li>
                    <li>View Detailed Game Results and Previews for Upcoming Games</li>
                    <li>Follow Live Games with Realtime Updates</li>
                    <li>Chat with Team Members (coming soon)</li>
                </ul>}
                { inviteType == 'follower' && <ul>
                    <li>View a Complete Schedule of Practices, Events, and Games</li>
                    <li>Track the Team’s Position in the Standings</li>
                    <li>View Detailed Game Results and Previews for Upcoming Games</li>
                    <li>Follow Live Games with Realtime Updates</li>
                    <li>Chat with Team Members (coming soon)</li>
                </ul>}
            </div> 
            
            { (app.layout.showTablet || app.layout.showDesktop) && <>
                <hr/>
                <div style={{ display:'flex', justifyContent:'space-between' }}>
                    <Button type="button" size="lg" variant={'inverted'} onClick={onBack}>Back</Button>
                    <Button type="button" size="lg" onClick={onAccept}>Continue</Button>
                </div>
            </>}

            { (app.layout.showMobile) && <>
                <div style={{ position: 'absolute', bottom: '0', width: 'calc(100% - 32px)', display: 'flex', justifyContent: 'space-between', paddingBottom: '16px', paddingLeft: '8px', paddingRight: '8px'}}>
                    <Button type="button" size="lg" variant={'inverted'} onClick={onBack}>Back</Button>
                    <Button type="button" size="lg" onClick={startInvitationFlow}>Continue</Button>
                </div>
            </>}

        </StyledTeamPreview>
    </StyledAcceptInvitationFlow>

}