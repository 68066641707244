import { config as devConfig } from "./config.dev"
import { config as qaConfig } from "./config.qa"
import { config as betaConfig } from "./config.beta"
import { config as prodConfig } from "./config.prod"
import { Config } from "./type"

const branch = process.env.REACT_APP_BRANCH || "main"
let config:Config = devConfig;

switch (true) {
    
    case /(feat\/.*)|(fix\/.*)|(qa.*)|(release\/.*)/i.test(branch): 
        config = qaConfig; break;
    
    case /^beta/i.test(branch): 
        config = betaConfig; break;
    
    case /(release\/.*)|main|demo|staging/i.test(branch): 
        config = prodConfig; break;
    
    default: 
        config = devConfig; break;
}

export default {
    ...config,
    app: process.env.REACT_APP_NAME || "app",
};