import { useAppState } from "@/state/app/useAppState";
import { useUserState } from "@/state/user/useUserState";
import { useCallback, useState } from "react";
import { firestore } from "@/libs/firebase";
import { getFirestore, collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { RosterPlayer } from "@/state/data/useTeamRoster/types";
import { useInvitationCodeService } from "@/flows/AcceptInvitationFlow/services/useInvitationCodeService";
import { FirestoreInvitation } from "@/services/useMemberService/types.ts/invitation";
import { getMembers } from "@/services/useMemberService/members/func.getMembers";
import { getInvitation } from "@/services/useMemberService/invitations/func.getInvitation";

type InvitationCodeServiceState = {
    isFetching: boolean,
    hasValidationError: boolean,
    validationError: string,
    onChange: number
}

export function useLoginFlowService() {

    // import app and user states
    const app = useAppState();
    const user = useUserState();
    const invitationCodeService = useInvitationCodeService();

    // initial state
    const initialState = {
        isFetching: false,
        hasValidationError: false,
        validationError: "",
        onChange: 0
    }

    // import service state
    const [service, _setServiceState] = useState({ ...initialState });
    const [ invitation, setInvitation ] = useState<FirestoreInvitation | undefined>(undefined);
    const setServiceState = (state: InvitationCodeServiceState) => _setServiceState({ ...state, onChange: Date.now() })

    const readPostgresInvitationCode = useCallback(async (code: string) => {

        // get code details
        const response = await invitationCodeService.loadCodeDetails(code).catch((err: any) => {
            setServiceState({ ...service, isFetching: false, hasValidationError: false, validationError: "The code you were provided is invalid. Please contact your league admin if you think this is a mistake." });
        });

        // save error
        if (response.status === 'error') {
            return response;
        }

        const schedulerInvite = response.data?.invitation?.roles?.some((role: any) => role.title === "scheduler")
        if (schedulerInvite) {
            // If the invitation is for a scheduler, the user needs to be routed to the admin dashboard.
            return "scheduler";
        }

        // set invitation and continue
        const invitation: FirestoreInvitation = {
            id: code,
            email: "",
            name: "",
            claim: {
                role: "staff",
            },
            type: "permanent",
            status: "pending",
            team: doc(firestore, `teams/${response.data?.teams[0].prototeam_id}`),
        }

        return invitation;

    }, [ invitationCodeService.loadCodeDetails ])

    const readFirebaseInviteCode = useCallback(async (id: string) => {
        // setState(state => ({ ...state, fInvitation: undefined, invitationTeam: undefined, inviterName: "", error: "" }));
        setServiceState({ ...service, hasValidationError: false, validationError: "" });
        setInvitation(undefined);
        var invitation: any = undefined;
        var inviteSource: string = "";
    
        // Get invite
        setServiceState({ ...service, isFetching: true })
        invitation = await getInvitation(id).catch((err: any) => {
            setServiceState({ ...service, isFetching: false, hasValidationError: true, validationError: "The code you were provided is invalid. Please contact your league admin if you think this is a mistake." });
        });
    
        // Check if invitation is valid
        if (invitation === undefined) {

            const pgInvitation = await readPostgresInvitationCode(id).catch((err: any) => {
                setServiceState({ ...service, isFetching: false, hasValidationError: true, validationError: "The code you were provided is invalid. Please contact your league admin if you think this is a mistake." });
            });;

            if (pgInvitation?.status == "error") {
                // setState(state => ({ ...state, loading: false, error: pgInvitation.errors.map((e: any) => e.message).join()}));
                setServiceState({ ...service, isFetching: false, hasValidationError: true, validationError: "The code you were provided is invalid. Please contact your league admin if you think this is a mistake." });
                return;
            } else if (pgInvitation == "scheduler") {
                setServiceState({ ...service, isFetching: false, hasValidationError: true, validationError: "Cannot accept a scheduler code in the Team App." });
                return;
            } else {
                invitation = pgInvitation;
            }
        }

        if (invitation.claim.role !== 'follower') {

            if ((invitation.type === "one_time") && (invitation.status !== "pending")) {
                setServiceState({ ...service, isFetching: false, hasValidationError: true, validationError: "The code you were provided is invalid. Please contact your league admin if you think this is a mistake." });
                return;
            }

        }

        // Set the flow state to have the required invitation data
        setInvitation(invitation);
        return invitation;
    
    }, [ getInvitation, setServiceState, service, readPostgresInvitationCode ])

    const loadCodeDetails = useCallback(async (code: string) => {
        console.log(code);
        const invitation = await readFirebaseInviteCode(code);

        if (invitation?.team?.id) {
            const team = await invitationCodeService.getTeam(invitation.team.id).catch((err: any) => {
                setServiceState({ ...service, isFetching: false, hasValidationError: true, validationError: "The code you were provided is invalid. Please contact your league admin if you think this is a mistake." });
                console.error(err);
            });
            if (team) {
                setServiceState({ ...service, isFetching: false });
                console.log({invitation, team});
                return {invitation, team};
            } else {
                setServiceState({ ...service, isFetching: false, hasValidationError: true, validationError: "The code you were provided is invalid. Please contact your league admin if you think this is a mistake." });
            }
                
        }
    }, [ readFirebaseInviteCode, invitationCodeService.getTeam, setServiceState, service ])

    return {

        // data
        ...service,

        // functions
        loadCodeDetails,
        loadingTeam: service.isFetching,
        reset: () => _setServiceState({ ...initialState }),
    }

}