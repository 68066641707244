import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Modal, Card, Alert, Button, QRCard } from "@gamesheet/ui";
import { BreakPoints } from "@/libs/breakpoints";
import { useSelectedTeamState } from "@/state/team/useSelectedTeamState";
import { InviteUserForm, InviteUserFormState } from "@/forms/InviteUserForm";
import { MemberClaim } from "@/services/useMemberService/types.ts/member";
import primaryBlack from "./components/primaryBlack.png";
import { useAppState } from "@/state/app/useAppState";
import copyIcon from "@/assets/images/copy-icon.png";

const StyledView = styled.div`
  width: 100%;
  padding-top: 1rem;

  @media screen and (min-width: ${BreakPoints.lg}) {
    padding-top: 0;
  }
`;

const Header = styled.div`
  color: #404247;
  font-size: 1rem;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
`;

const SmallDivider = styled.div`
  border: 1px solid #d7d7d8;
  margin-bottom: 1rem;
  padding-left: -1rem;
  width: 100%;
`;

const Divider = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  margin: 1rem 0;
  font-size: 20px;

  &::before,
  &::after {
    content: '';
    flex: 1;
    border-top: 3px solid #d7d7d8;
  }

  &:not(:empty)::before {
    margin-right: 0.5em;
  }

  &:not(:empty)::after {
    margin-left: 0.5em;
  }
`;


const InfoText = styled.div`
  color: #000;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 1rem 0;
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .button {
    font-size: 1rem !important;
    font-weight: 500 !important;
  }
`;

const SSButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 0.3125rem;
  border: 2px solid #36383D;
  color: #36383D;
  font-size: 20px;
  font-style: normal;
  font-family: 'Kapra Neue', sans-serif;
  font-weight: 600;
  line-height: 24px;
  font-name: headings/heading-xxs;
  margin-bottom: 0.5rem;
  width: 50%;
  text-wrap: none;
  height: 60px;

  img {
    width: 1.75rem;
    height: 1.75rem;
  }

`

const ContentContainer = styled.div`

`;

type InviteFollowerProps = {
  onBack: () => void;
  onSubmit: (emails: string, type: MemberClaim) => void;
  inviteCode: string;
  staff: boolean;
};

export const InviteFollower: React.FC<InviteFollowerProps> = ({ onBack, onSubmit, inviteCode, staff }) => {
  const app = useAppState();
  const [isValid, setIsValid] = useState(false);
  const [formData, setFormData] = useState<InviteUserFormState>();
  const claim: MemberClaim = {role: 'follower'};
  const handleSubmit = () => { 
    if (formData) {
      onSubmit(formData.emails.value, claim);
    }
  }

  const baseUrl = (() => {
    switch (app.config.name) {
      case 'Production':
        return 'https://teams.gamesheet.app';
      case 'QA':
        return 'https://qa1.teams.gamesheet.app';
      case 'QA Local':
        return 'https://qa1.teams.gamesheet.app';
      default:
        return 'https://dev.teams.gamesheet.app';
    }
  })();

  
  return (
    <StyledView>
      <div style={{marginBottom: '1rem', justifyContent: 'center', display: 'flex'}}>
        <QRCard 
          url={`${baseUrl}/invitation/?invitation=` + inviteCode} 
          logo={primaryBlack}
        />
      </div>
      <div style={{display: 'flex', alignContent: 'center', justifyContent: 'center'}}> <SSButton onClick={() => navigator.clipboard.writeText(`${baseUrl}/invitation/?invitation=` + inviteCode)}>COPY LINK <img src={copyIcon} alt="copy-icon" /></SSButton> </div>

      <Divider>OR</ Divider>

      <ContentContainer>
          <InviteUserForm onSubmit={handleSubmit} setIsValid={setIsValid} setFormData={setFormData} />
          <InfoText>To send multiple invitations, separate additional emails by commas.</InfoText>
      </ContentContainer>

      <SmallDivider />

      <FooterContainer>
        {staff && <Button variant="inverted" label="Cancel" onClick={() => onBack()}>
          Cancel
        </Button>}
        {isValid && formData ? <Button onClick={handleSubmit}>Send Invitation</Button> : <Button state="disabled" variant="inverted" label="Send Invitation" />}
      </FooterContainer>
    </StyledView>
  );
};
