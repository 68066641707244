import { ManagedFlow } from "@/hooks/useManagedFlow";

export type InviteUserFlowType = ManagedFlow<InviteUserFlowStep, InviteUserFlowPath> & {
  loading: boolean,
  isOpen: boolean,
  error: string,
  userEmail: string,
  oldInvitationId: string,
  staffInvite: boolean,
  type: "follower" | "staff" | undefined
}

export enum InviteUserFlowStep {
  SelectRole = 0,
  InviteFollower,
  InviteStaff,
}

export enum InviteUserFlowPath {
  InviteUser = "inviteUser",
}
