import { useUserState } from "@/state/user/useUserState";
import { useSelectedTeamState } from "@/state/team/useSelectedTeamState";
import { useCallback, useState } from "react";
import { atom, useRecoilState } from "recoil";
import { useAppState } from "@/state/app/useAppState";
import { InviteUserFormState } from "@/forms/InviteUserForm";
import { InviteUserFlowState } from "../state/atom.inviteUserFlowState";
import { firestore } from '@/libs/firebase';
import { collection, query, where, doc, setDoc, getDocs, onSnapshot } from "firebase/firestore";
import { createInvitation, InvitationCreation } from "@/services/useMemberService/invitations/func.createInvitation";
import { MemberClaim } from "@/services/useMemberService/types.ts/member";
import { InvitationType, Invitation } from "@/services/useMemberService/types.ts/invitation";
import { getAllInvitations } from "@/services/useMemberService/invitations/func.getAllInvitations";
import { addEmailSent, rejectInvitation } from "@/services/useMemberService/invitations/func.markInvitation";
import { FirestoreInvitation } from "@/services/useMemberService/types.ts/invitation";

export function useInviteUserService(to?: (pathIndex: number) => void){
  const app = useAppState();
  const user = useUserState();
  const selectedTeam = useSelectedTeamState();
  const [ followerInvitation, setFollowerInvitation ] = useState<Invitation>();
  const [ staffQRInvitation, setStaffQRInvitation ] = useState<Invitation>();

  const [{ loading, isOpen, userEmail, oldInvitationId, staffInvite }, setState] = useRecoilState(InviteUserFlowState);

  const open = useCallback((staffInvite: boolean, email?: string, invitationId?: string) => {
    setState((prev) => ({
      ...prev,
      isOpen: true,
      userEmail: email || "",
      oldInvitationId: invitationId || "",
      staffInvite,
    }));
    document.body.style.overflow = "hidden";
  }, [setState]);

  const close = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isOpen: false,
      userEmail: "",
    }));

    document.body.style.overflow = "auto";
  }, [setState]);

  const setLoading = useCallback((loading: boolean) => {
    setState((prev) => ({
      ...prev,
      loading: loading,
    }));
  }, [setState]);

  const getInvitations = useCallback(async() => {
    let invitations: FirestoreInvitation[] = [];
    invitations = await getAllInvitations(app.selectedTeam);
    let fInvitation = undefined;
    let sInvitation = undefined;

    if (!followerInvitation) {
      // Find follower invitation for this team.
      for (const invitation of invitations) {
        if (invitation.claim?.role === 'follower' && invitation.status == 'pending') {
          fInvitation = invitation;
          setFollowerInvitation(invitation);
          break;
        }
      }
      // If one doesn't exist, create one.
      if (!fInvitation) {
        // Create follower invitation for this team.
        const newInvitation = await handleAddInvitation("", { role: 'follower' }, false);
        setFollowerInvitation(newInvitation);
      }
    }

    if (!staffQRInvitation && app.selectedTeamClaim?.role === 'staff') {
      // Go find staff QR invitation for this user's email.
      for (const invitation of invitations) {
        // Invitation codes don't expire
        // if (invitation.claim?.role == 'staff' && invitation.type == 'qr' && (invitation.expiresAt ? (new Date(invitation.expiresAt).getTime() > (Date.now())) : true) && invitation.email == user.email) {
        if (invitation.claim?.role == 'staff' && invitation.type == 'qr' && invitation.email == user.email) {
        sInvitation = invitation;
          setStaffQRInvitation(invitation);
          break;
        }
      }

      if (!sInvitation) {
        // Create staff QR invitation for this user's email.
        const newInvitation = await handleAddInvitation(user.email || "", { role: 'staff' }, true);
        setStaffQRInvitation(newInvitation);
      }
    }
    return { followerInvitation, staffQRInvitation };
  }, [JSON.stringify(followerInvitation), JSON.stringify(staffQRInvitation), user.email, app.selectedTeam]);

  const handleAddInvitation = useCallback(async (email: string, type: MemberClaim, qr: boolean) => {
      try {
          if (!selectedTeam) {
            throw new Error('No team selected');
          }

          const invitationType = type.role === "staff" ? (qr ? 'qr' : 'one_time') : 'permanent';
          const name = type.role === 'staff' ? user.extraDetails.firstName + ' ' + user.extraDetails.lastName : ""

          const invitation: InvitationCreation = {
            name: name,
            email: email,
            claim: type,
            type: invitationType,
            status: 'pending',
          }

          const inviteId = await createInvitation(selectedTeam.id, invitation);

          const newInvitation: Invitation = {
            id: inviteId || "",
            email: email,
            name: name,
            claim: type,
            type: invitationType,
            status: 'pending',
            team: doc(firestore, "teams", app.selectedTeam),
          }

          to && to(0);
          return newInvitation;
      } catch (error) {
          setLoading(false);
          console.error('Error adding invitation: ', error);
      }
  }, [ JSON.stringify(selectedTeam), user.extraDetails.firstName, user.extraDetails.lastName, app.selectedTeam])

  const sendEmail = useCallback(async (email: string, invitation: Invitation) => {
    const requestData = {
      attributes: {
        event: "send-invitation-email",
        schema: "invitation-emails",
        version: "v2",
      },
      data: {
        invitee_email: email,
        inviter_email: user.email,
        team_name: selectedTeam?.title,
        invite_code: invitation.id,
        invite_claim: invitation.claim.role,
      },
    };

    const url = `${app.config.gateways.eventLog}/v5/teams/${invitation.team.id}/send-invitation-email`;
    const headers = new Headers();
    user.isLoggedIn && headers.set("Authorization", `Bearer ${user.tokens.access}`);

    try {
      const response = await fetch(url, {
        method: "POST",
        headers,
        body: JSON.stringify(requestData),
      });

      addEmailSent(invitation.team.id, invitation.id, email);
      oldInvitationId != "" && rejectInvitation(app.selectedTeam, oldInvitationId);

      return response;
    } catch (error) {
      console.error("Error while sending invitation emails", error);
      return error;
    }

  }, [app.config.gateways.eventLog, user.tokens.access, user.isLoggedIn, selectedTeam?.title, user.email, oldInvitationId]);

  const onSubmit = useCallback(async (emails: string, type: MemberClaim, invitationType: InvitationType, invitation?: Invitation) => {
    // If the role is follower, find a follower invite for the team, if none exist, create one.
    const emailList = emails
        .split(",")
        .map((email) => email.trim());
    if (type.role === 'staff') {
      // Create a new one_time invitation for the staff invite.
      setLoading(true);
      for (const email of emailList) {
        const staffInvitation = await handleAddInvitation(email, type, invitationType !== 'one_time');
        // Send email with staffInvitation code to the email
        if (staffInvitation) await sendEmail(email, staffInvitation);
      }
      setLoading(false);
    } else {
      setLoading(true);
      for (const email of emailList) {
        // Send email with invitation code to the email
        if (invitation) await sendEmail(email, invitation);
      }
      setLoading(false);
    }
    // Send email with invitation
    close();
  }, [user.tokens.access, selectedTeam?.id, close, setLoading, sendEmail, handleAddInvitation]);

  return {
    loading,
    open,
    close,
    isOpen,
    onSubmit,
    handleAddInvitation,
    getInvitations,
    userEmail,
    staffInvite
  };
}