import { useEffect } from "react"
import { useAppState } from "@/state/app/useAppState"
import { Logo } from "@gamesheet/ui"

import ScheduleIcon from '@mui/icons-material/DateRange';
import RosterIcon from '@mui/icons-material/Groups';
import StatsIcon from '@mui/icons-material/Scoreboard';
import MoreIcon from '@mui/icons-material/MoreHoriz'
import { Message } from "@mui/icons-material";

import { Outlet } from "react-router-dom";
import styled from "styled-components";
import { useReadTeamsData } from "@/state/data/useTeams";
import { useReadTeamEventsData } from "@/state/data/useTeamEvents";
import { LoadingLayout } from "@/layouts/LoadingLayout";

import { useUserState } from "@/state/user/useUserState";
import { useReadActiveRosterData } from "@/state/data/useTeamRoster/useReadActiveRosterData";
import { useUpdateService } from "@/services/useVersionService";
import { InvitationPage } from "@/pages/user.invitation";
import { IsolatedLayout } from "@/layouts/IsolatedLayout";
import PWAPrompt from "react-ios-pwa-prompt";
import { useReadChannelsState } from "@/state/messaging/useChannelsState";
import { useReadMembersState } from "@/state/members/useMembersState";
import DynamicMessageIcon from "@/components/DynamicMessageIcon";
import { ClubTeams } from "@/pages/user.club.teams.only";

const DesktopIconBar = styled.div`
height: 32px;
background-color: ${props => props.theme.primaryColor};
padding: 4px;
position: fixed;
left: 0;
top: 0;
right: 0;
z-index: 400;

div {
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    button {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: ${props => props.theme.textOnPrimaryColor};
        background-color: transparent;
        border: none;
    }
}
`

const StyledLogo = styled(Logo).attrs<{
    className?: string
}>({
    className: "logo"
})`
height: 24px;
margin: 5px;
padding: 5px;
`

export function TeamsApp() {

    const app = useAppState()
    const user = useUserState()
    const events = app.events

    useReadTeamsData()
    useReadTeamEventsData()
    useReadActiveRosterData()
    useUpdateService()
    useReadMembersState(app.selectedTeam)

    const userClaims = user.GetClaims();
    const isTeamsLoadingComplete = app.loading.isComplete('teams');
    const loadingClubTeams = app.loading.isComplete('clubTeams');
    const showClubUser = (user.extraDetails.clubTeams.length > 0) && app.teams.count === 0 && isTeamsLoadingComplete && !loadingClubTeams;
    const showLoadingLayout = !showClubUser && (!isTeamsLoadingComplete || (app.teams.isUnderThreshold && user.isRecentSignup)) && !app.teams.loaded;
    const showTooFewTeams = !showClubUser && app.teams.isUnderThreshold && isTeamsLoadingComplete;

    useEffect(() => {

        app.navigation.set([
            { to: "/games", icon: <ScheduleIcon />, label: "Schedule", mobile: "left", desktop: true },
            { to: "/roster", icon: <RosterIcon />, label: "Roster", mobile: "left", desktop: true },
            { to: "/games", mobile: "icon" },
            { to: "/seasons", icon: <StatsIcon />, label: "Seasons", mobile: "right", desktop: true },
            // { to: "/chat", icon: <DynamicMessageIcon unreadMessageCount={app.chat.unreadMessages} />, label: "Messages", mobile: "right", desktop: true},
            { action: events.handler('toggle-more-menu'), icon: <MoreIcon />, label: "More", mobile: "right" },
        ])

    // }, [ app.chat.unreadMessages ])
    }, [])

    useEffect(() => {
        const expireAsync = async () => {
            await user.Expire().catch((error) => {
                console.log(error);
                app.setAcceptedInvite(false);
            });
            app.setAcceptedInvite(false);
        };
        app.inviteAccepted && expireAsync();
    }, [app.inviteAccepted]);

    if (showClubUser) {
        if ((app.location.pathname !== "/club-user" && app.location.pathname !== "/join-team" && !app.location.pathname.startsWith("/invitation")) || app.location.pathname + app.location.search === "/invitation?noteams=true") {
            app.navigate("/club-user")
        }
    }

    if (showLoadingLayout) {
        return <LoadingLayout />
    }

    if (showTooFewTeams && app.teams.loaded && !loadingClubTeams) {
        if (!app.location.pathname.startsWith("/invitation")) {
            app.navigate("/invitation?noteams=true")
        }
    } else if (app.location.pathname + app.location.search === "/invitation?noteams=true") {
        app.navigate("/roster");
        window.location.reload();
    }

    return <>

        <Outlet key={app.lastChange} />
        { user.isLoggedIn && app.location.pathname === '/games' && <PWAPrompt timesToShow={100} promptOnVisit={1} appIconPath='/teamlogo.png' copyTitle='Install GameSheet Teams' copySubtitle='Manage Your Team’s Schedule, Roster, and Stats in One App' copyDescription='Install the GameSheet Teams app for full-screen mode, faster loading, push notifications, and quick access from your Home screen.' copyShareStep="Tap the 'Share' button on the menu bar below." copyAddToHomeScreenStep="Scroll down and tap the 'Add to Home Screen' option."  />}

    </>
}