import { firestore } from "@/libs/firebase";
import { arrayRemove, arrayUnion, doc, updateDoc } from "firebase/firestore";

export async function addClubTeam(
    team: string,
    userId: string
){
    const userRef = doc(firestore, `users/${userId}`);

    try {
        await updateDoc(userRef, {
            clubTeams: arrayUnion(team)
        });
    } catch (error) {
        console.error("Error adding club team: ", error);
    }
}

export async function removeClubTeam(
    team: string,
    userId: string
){
    const userRef = doc(firestore, `users/${userId}`);

    try {
        await updateDoc(userRef, {
            clubTeams: arrayRemove(team)
        });
    } catch (error) {
        console.error("Error removing club team: ", error);
    }
}