import { ManagedFlow } from "@/hooks/useManagedFlow";
import { useInvitationCodeService } from "./services/useInvitationCodeService";
import { TeamData } from "@/state/data/useTeams";
import { FirestoreInvitation } from "@/services/useMemberService/types.ts/invitation";
import { MemberClaim } from "@/services/useMemberService/types.ts/member";
import { Roster, RosterPlayer } from "@/state/data/useTeamRoster/types";

export type InsertInvitationCodeProps = {
    onSubmit: (code:string) => void;
    error?: string;
    register:boolean;
    noTeams: boolean;
}

export type AcceptInvitationFlowType = ManagedFlow<AcceptInvitationFlowStep, AcceptInvitationFlowPath> & {
    loading: boolean,
    error: string,
    mergeError: string,
    invitationCode: string,
    inviterName: string,
    indirectStaff: boolean,
    schedulerInvitation: boolean,
    inviteSource: string,
    fInvitation: FirestoreInvitation | undefined,
    invitationType: MemberClaim,
    invitationTeam: Prototeam | undefined,
    teamRelationship: Relationship | undefined,
    phoneNumber: string,
    rawMergedData: RawMergedData | undefined,
    prototeamOptions: PrototeamOptions,
}

export enum AcceptInvitationFlowPath {
    NewUser = 'newUserPath',
    ExistingUser = 'existingUserPath',
    MigrateTeam = 'migrateTeamPath',
}

export enum AcceptInvitationFlowStep {
    InsertInvitationCode = 0,
    PreviewTeam, // 1
    PreviewRoster, // 2
    TeamRelationship, // 3
    SwitchToDashboard, // 4
    RegisterNewAccount, // 5
    PhoneNumber, // 6
    PasswordConfirmation, // 7
    PrototeamOptions, // 8
    SelectedOptionSummary, // 9
    Success, // 10
}

export type InvitationTeam = {
    id: number;
    title: string;
    prototeam_id: string;
    deleted_at: string;
    logo: string;
    season: {
        id: number;
        title: string;
    },
    division: {
        id: number;
        title: string;
    },
    players: Array<InvitationPlayer>
}

export type SeasonTeam = {
    id: number;
    title: string;
    season: {
        id: number;
        title: string;
        archived: boolean;
        sport: string;
    },
    division: {
        id: number;
        title: string;
    },
    association: {
        id: number;
        title: string;
    },
    league: {
        id: number;
        title: string;
    },
}

export type Prototeam = {
    id: string;
    title: string;
    sport: string;
    logo: string;
    players: RosterPlayer[];
    seasonteams: SeasonTeam[];
}

export type InvitationPlayer = {
    id: number;
    firstName: string;
    lastName: string;
    number: string;
    photo: string;
    duty: string;
}

export type Invitation = {
    invitation?: {
        code: string;
        description: string;
        id: string;
        roles: Array<{
            title: string;
            level: {
                id: string;
                type: string;
            }
        }>
    },
    teams?: InvitationTeam[],
    userAlreadyHasTeamRoles: {
        [key:number]: boolean
    },
}

export type useAcceptInvitationFlowType = AcceptInvitationFlowType & {
    code: ReturnType<typeof useInvitationCodeService>;
} & {
    showCodeInput: boolean,
    showSwitchToDashboard: boolean,
    showTeamRelationship: boolean,
    showTeamPreview: boolean,
    showTeamRoster: boolean,
    showSchedulerPreview: boolean,
    showAccountRegistration: boolean,
    showPhoneNumber: boolean,
    showPasswordConfirmation: boolean,
    showPrototeamOptions: boolean,
    showSelectedOptionSummary: boolean,
    showSuccess: boolean,
};

export type RegistrationData = {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    phoneNumber: string;
}
export type PrototeamOptions = {
    strategy: Strategy,
    linkedTeam: TeamData | undefined,
}

export type Relationship = {
    relationship: string
    relatedPlayers?: RosterPlayer[]
}

export type Strategy = "default" | "link" | "add";

export type RawMergedData = {
    alreadyInPrototeam: boolean,
    players: Array<InvitationPlayer>,
    logo: string,
    title: string,
}
