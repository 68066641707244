import { Title } from "@gamesheet/ui";
import { InsertInvitationCode } from "./step.InsertInvitationCode";
import { useAcceptInvitationFlow } from "./state/useAcceptInvitationFlow";
import { PageLoading } from "@/components/pure/PageLoading";
import { PreviewTeam } from "./step.PreviewTeam";
import { PreviewRoster } from "./step.PreviewRoster";
import { PreviewMerge } from "./step.PreviewMerge";
import { PrototeamOptions } from "./step.PrototeamOptions";
import { AcceptInvitationFlowPath } from "./types";
import { styled } from "styled-components";
import { useAppState } from "@/state/app/useAppState";
import { RegisterNewAccount } from "./step.RegisterNewAccount";
import useQueryParams from "@/hooks/useQueryParams";
import { useCallback, useEffect, startTransition, useState } from "react";
import { SchedulerPreview } from "./step.SchedulerPreview";
import { useInvitationCodeState } from "./state/useInvitationCodeState";
import { TeamRelationship } from "./step.TeamRelationship";
import { Switch } from "@mui/material";
import { SwitchToDashboard } from "./step.SwitchToDashboard";
import { PhoneNumber } from "./step.PhoneNumber";
import { useUserState } from "@/state/user/useUserState";
import { PasswordConfirmation } from "./step.PasswordConfirmation";

const MobileAcceptInvitationFlow = styled.div`
    background-size: cover;
    background-position: right;
    background-attachment: fixed;
    background-color: #fff;
    min-height: calc(100vh - 32px);
    padding: 16px;
    border-radius: 16px;
`

const DesktopAcceptInvitationFlow = styled.div`
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
`

type Props = {
    path: AcceptInvitationFlowPath
    teamId?: string
    onDone?: () => void
}

export function AcceptInvitationFlow({ path }: Props) {

    const app = useAppState()
    const user = useUserState()
    const StyledAcceptInvitationFlow = (app.layout.showTablet || app.layout.showDesktop) ? DesktopAcceptInvitationFlow : MobileAcceptInvitationFlow
    const flow = useAcceptInvitationFlow(path);
    const queryParams = useQueryParams();
    const [ _code, _setCode ] = useInvitationCodeState();
    const [ title, setTitle ] = useState("Accept Invitation")
    const [ loadingTeam, setLoadingTeam ] = useState(true);
    const [ manualTeamId, setManualTeamId ] = useState<string | null>(null);
    const [ noTeams, setNoTeams] = useState(false);
    const [ fromEmail, setFromEmail ] = useState(false);

    useEffect(() => {
        const code = queryParams['invitation']
        const teamId = queryParams['teamId']
        const _noTeams = queryParams['noteams']
        const signedIn = queryParams['signedIn']
        const _fromEmail = queryParams['fromEmail']
        
        if (code && !signedIn && !_code) {
            flow.readFirebaseInviteCode(code)
            _setCode(code)
        }
        if (code && signedIn && !_code) {
            flow.readFirebaseInviteCode(code)
            _setCode(code)
            // flow.skipTeamPreview()
        }
        if (!manualTeamId && teamId) {
            setManualTeamId(teamId);
            flow.setTeamInvitation(teamId);
        }
        if (!noTeams && _noTeams) {
            setNoTeams(true);
        }
        if (!fromEmail && _fromEmail) {
            setFromEmail(true);
        }
    },[ JSON.stringify(queryParams), flow.readFirebaseInviteCode, flow.skipTeamPreview, flow.setTeamInvitation, _setCode, _code, noTeams, fromEmail, manualTeamId ])

    useEffect(() => {

        if (manualTeamId && !flow.sampleTeam) {
            setLoadingTeam(true);
        } else {
            setLoadingTeam(false);
        }

    }, [ manualTeamId, JSON.stringify(flow.sampleTeam) ])

    useEffect(() => {
        if (flow.showCodeInput) setTitle("Accept Invitation");
        if (flow.showTeamPreview) setTitle("You're Invited!");
        if (flow.showTeamRelationship) setTitle("Team Relationship");
        if (flow.showAccountRegistration) setTitle("Registration");
        if (flow.showPhoneNumber) setTitle("Missing Information");
        if (flow.showPasswordConfirmation) setTitle("Missing Information");
        if (flow.showSwitchToDashboard) setTitle("Switch To Dashboard");
        if (flow.showPrototeamOptions) setTitle("Accept Invitation");
        if (flow.showSelectedOptionSummary) setTitle("Accept Invitation");
    }, [flow.showCodeInput, flow.showTeamPreview, flow.showTeamRelationship, flow.showAccountRegistration, flow.showPhoneNumber, flow.showPasswordConfirmation, flow.showSwitchToDashboard, flow.showPrototeamOptions, flow.showSelectedOptionSummary])

    if (flow.isLoading || loadingTeam) {
        return <StyledAcceptInvitationFlow><PageLoading /></StyledAcceptInvitationFlow>
    }

    return <StyledAcceptInvitationFlow>
        <Title bartype="divider" text={title} size="h4"/>

            {/* Insert Invitation Code */}
            {flow.showCodeInput && <InsertInvitationCode
                onSubmit={flow.readFirebaseInviteCode}
                error={flow.error}
                register={flow.path === 'newUserPath'}
                noTeams={noTeams}
            />}

            {/* Team Preview */}
            {flow.showTeamPreview && flow.sampleTeam?.title && <PreviewTeam
                logo={flow.sampleTeam.logo}
                title={flow.sampleTeam.title}
                name={flow.inviterName}
                inviteType={flow.invitationType.role}
                register={flow.path === 'newUserPath'}
                onBack={flow.controls.prev}
                onAccept={flow.previewNext}
            />}

            {/* Show Roster Preview */}
            {flow.showTeamRoster && flow.sampleTeam?.players && flow.sampleTeam?.seasonteams && flow.sampleTeam && <PreviewRoster
                logo={flow.sampleTeam.logo}
                title={flow.sampleTeam.title}
                season={flow.sampleTeam?.seasonteams[0]?.season}
                division={flow.sampleTeam?.seasonteams[0]?.division}
                register={flow.path === 'newUserPath'}
                onBack={flow.toStart}
                onAccept={flow.controls.next}
                roster={{
                    players: flow.sampleTeam.players,
                    skaters: flow.sampleTeam.players,
                    coaches: [],
                    goalies: [],
                }}
        />}


            {/* Get Team Relationship */}
            {flow.showTeamRelationship && <TeamRelationship
                inviteType={flow.invitationType}
                hasNumber={user.phoneNumber != ""}
                title={flow.sampleTeam?.title || ""}
                players={flow.sampleTeam?.players || []}
                register={flow.path === 'newUserPath'}
                fromEmail={fromEmail}
                migrate={!!flow?.path ? (flow.path === "migrateTeamPath") : false}
                onBack={flow.toPreview}
                onNext={flow.setRelationship}
            />}

            {/* Account Registration */}
            {flow.showAccountRegistration && <RegisterNewAccount
                onBack={flow.backToTeamRelationship}
                onAccept={flow.registerNewAccount}
                onSSO={flow.handleSSOLogin}
                register={flow.path === 'newUserPath'}
                relationship={flow.teamRelationship?.relationship || ""}
                error={flow.error}
            />}

            {/* Phone Number */}
            {flow.showPhoneNumber && <PhoneNumber 
                onSubmit={flow.UpdateFirebaseDetails}
                onBack={flow.backToTeamRelationship}
                register={flow.path === 'newUserPath'}
                role={flow.invitationType.role}
            />}

            {/* Password Confirmation */}
            {flow.showPasswordConfirmation && <PasswordConfirmation 
                register={flow.path === 'newUserPath'}
                onSubmit={flow.setPasswordConfirmation}
                onBack={flow.backToTeamRelationship}
                error={flow.error}
            />}

            {/* Switch To Dashboard */}
            { flow.showSwitchToDashboard && <SwitchToDashboard 
                register={flow.path === 'newUserPath'} 
                onSwitch={flow.continueFromClubNotice}
                onBack={flow.schedulerInvitation ? flow.resetFlow : flow.backToTeamRelationship}
            />}

            {/* Prototeam Merge Options */}
            {flow.showPrototeamOptions && flow.sampleTeam && <PrototeamOptions
                register={flow.path === 'newUserPath'}
                title={flow.sampleTeam.title || ""}
                onBack={flow.backToTeamRelationship}
                invitationTeams={flow.sampleTeam.seasonteams}
                onAccept={flow.strategy === "add" ? flow.acceptFirebaseInvitationCode : flow.previewMerge}
                error={flow.mergeError}
            />}

            {/* Merged Roster Preview */}
            {flow.showSelectedOptionSummary && flow.mergedPreview && <PreviewMerge
                logo={flow.mergedPreview.logo}
                title={flow.mergedPreview.title}
                prototeam={flow.mergedPreview.prototeam}
                season={flow.mergedPreview.seasonteams[0].season}
                division={flow.mergedPreview.seasonteams[0].division}
                register={flow.path === 'newUserPath'}
                onBack={flow.controls.prev}
                onAccept={flow.acceptFirebaseInvitationCode}
                roster={{
                    players: flow.mergedPreview.roster,
                    coaches: [],
                    goalies: [],
                    skaters: flow.mergedPreview.roster
                }}
            />}

        </StyledAcceptInvitationFlow>
}